import { Flex, Text, Box, useTheme, Link,Avatar} from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
import getData, { convertHTMLString, keys } from '../data';

const UsefulLinks = props => {
  const theme = useTheme()
  return (
    <MainContainer heading="Useful Links">
      {getData(props.data.school, keys.USEFUL_LINKS) ? (
        <Box w={{ base: "100%", sm: "100%", lg: "25%" }} p={10}>
          <Box
            dangerouslySetInnerHTML={convertHTMLString(
              getData(props.data.school, keys.USEFUL_LINKS)
            )}
          />
        </Box>
      ) : null}
      <Flex direction="column" m="30px">
        {getData(props.data.school, keys.USEFUL_LINK_POSTS).map((val, i) => (
          <Box key={i} className="birthday-card" p="10px" m="10px">
            <Link key={val.id} target="_blank" href={val.link}>
              <Flex direction="row">
                <Box mr="20px" p="10px">
                  <Avatar src={val.image || "/images/link.png"} />
                </Box>
                <Text p="10px"> {val.title}</Text>
              </Flex>
            </Link>
          </Box>
        ))}
      </Flex>
    </MainContainer>
  );
};

export default UsefulLinks;
