import React, { useState } from 'react'
import { Box, Text, Stack, Flex, Button, useTheme } from "@chakra-ui/react"
import MainContainer from '../components/MainContainer'
import _ from 'lodash'

import { useHistory } from 'react-router';

import { convertHTMLString } from '../data';

const Courses = ({ data }) => {

    const history = useHistory()
    let courses = _.filter(data.school.standards?.data, course => course.website == 1)
    const [currentCourse, setCurrentCourse] = useState(history.location.state ? courses[history.location.state] : courses[0])
    const theme = useTheme()
    return (
        <>
            <MainContainer heading="Courses">
                <Box p={['1em', "2em"]}>
                    <Flex direction="row" flexWrap='wrap' >
                        <Stack px="2em" py={['1em', '3em']} w={['100%', '100%', '100%', "30%"]}>
                            {courses.map((course, index) =>
                                <Box id={index} key={index} my="1em" textAlign='center' shadow="lg"
                                    onClick={() => setCurrentCourse(course)}
                                    rounded="lg" cursor="pointer" p="20px" _hover={{
                                        shadow: "2xl"
                                    }}
                                    bg={currentCourse && currentCourse['name'] == course['name'] ? theme.primary : ''}
                                    color={currentCourse && currentCourse['name'] == course['name'] ? 'white' : ''}
                                >{course['name']}</Box>
                            )}

                        </Stack>

                        {currentCourse ? <Box w={['100%', '100%', '100%', '60%']} my="2em">
                            <Text fontSize="2xl">{currentCourse['name']}</Text>
                            <CourseDescription title='Mode' field='type' currentCourse={currentCourse} /> 
                            <CourseDescription title='About' field='description' currentCourse={currentCourse} />
                            <CourseDescription title='Status' field='status' currentCourse={currentCourse} />
                            <CourseDescription title='Eligibility' field='eligiblity' currentCourse={currentCourse} />
                            <CourseDescription title='Exam Pattern' field='exam_pattern' currentCourse={currentCourse} />
                            <CourseDescription title='Cut off' field='cutoff' currentCourse={currentCourse} />
                            <CourseDescription title='Syllabus' field='syllabus' currentCourse={currentCourse} />
                            <CourseDescription title='Price(INR)' field='price' currentCourse={currentCourse} />



                        </Box> : null}
                    </Flex>


                </Box>
            </MainContainer>
        </>
    )
}

export default Courses

const CourseDescription = ({ title, field, currentCourse }) => {

    // this function extracts data from html code and check its length
    function htmlRegex(field){
        var regex = /(<([^>]+)>)/ig
         let  result = field.replace(regex, "");
         if(result.length>1){
             return true
         }
         else{
             return false
         }
    }



    return (
        <>
            {
                currentCourse[field] && htmlRegex(currentCourse[field]) ?
                    <Box w='100%' >
                        <Text p='5px' mt="1em" bg='gray.100' fontSize="xl" >{title}</Text>
                        <Box px="1em" fontSize="lg" dangerouslySetInnerHTML={convertHTMLString(currentCourse[field])} />
                    </Box> :
                    null
            }

        </>
    )
}