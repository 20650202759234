import { theme, extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
};

let colorData = {
  primary: { primary: "#3796F2", secondary: "#F29337" },
  danger: { primary: "#F74880", secondary: "#308d6f" },
  secondary: { primary: "#F74780", secondary: "#F89D35" },
  success: { primary: "#68D391", secondary: "#F0A868" },
  grayscale: { primary: "#746A6A", secondary: "#6A7474" },
  purple: {primary: "#682a8d", secondary: "#d5ad2a"}
};

var customTheme = extendTheme({
  breakpoints,
  primary:colorData.primary,
  secondary:colorData.secondary,
  headingFont: "web-font",
  colors: {
    ...theme.colors,
    primary:colorData.primary,
    secondary:colorData.secondary
  },
});

export var newTheme;
export const getTheme = (props) => {
  let newColor = colorData[props.school.theme]
    ? colorData[props.school.theme]
    : colorData.primary;
  newTheme = null;
  newTheme = extendTheme({
    breakpoints,
    primary: newColor.primary,
    secondary: newColor.secondary,
    headingFont: "web-font",
    colors: {
      ...theme.colors,
      primary: newColor.primary,
      secondary:newColor.secondary,
    },
  });
};
export default customTheme;
