import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Home from "./routes/Home";
import "./styles/index.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AboutUs from "./routes/AboutUs";
import Affiliation from "./routes/Affiliation";
import VisionAndMission from "./routes/VisionAndMission";
import TimeTable from "./routes/TimeTable";
import Curriculum from "./routes/Curriculum";
import AdmissionRules from "./routes/AdmissionRules";
import FeeDetails from "./routes/FeeDetails";
import EligibilityCriteria from "./routes/EligibilityCriteria";
import Gallery from "./routes/Gallery";
import OnlineAdmission from "./routes/OnlineAdmission";
import KheloIndia from "./routes/KheloIndia";
import ContactUs from "./routes/ContactUs";
import AllFacilities from "./routes/AllFacilities";
import Downloads from "./routes/Downloads";
import CbseDownloads from './routes/CbseDownloads';
import Career from "./routes/Career";
import UsefulLinks from "./routes/UsefulLinks";
import TransferCertificate from "./routes/TransferCertificate";
import FAQ from "./routes/FAQ";
import SchoolUniform from "./routes/SchoolUniform";
import SchoolCalendar from "./routes/SchoolCalendar";
import Team from "./routes/Team";
import Achievements from "./routes/Achievements";
import NoMatch from "./routes/NoMatch";
import Desk from "./routes/Desk";
import Birthdays from "./routes/Birthdays";
import Path from "./data/Path";
import getData, { keys } from "./data";
import { Helmet } from "react-helmet";
import { getTheme } from "../src/theme/index";
import Home2 from "../src/components/Template2/Home2"
import { getTemplateDesign } from "./redux/actions";
import WebsitePopup from "./routes/WebsitePopup";
import MandatoryDisclosure from "./routes/MandatoryDisclosure";
import Home3 from "./components/Template3/Home3";
import Courses from "./routes/Courses";
import ScholarshipAdmission from "./routes/ScholarshipAdmission";
import Home4 from "./components/Template4/Home4";
import Home5 from "./components/Template5/Home5";

const App = () => {
  const data = useSelector((state) => state);
  console.log("dataIsHere::", data);
  useEffect(() => {
    getTheme(data);
  }, []);
  //const templateDesign = 5
  const templateDesign = data.school?.website_template ? data.school?.website_template : '0'  // define  template design here  => 0: Old Template   1: New template
  // const templateDesign=3

  return (
    <ChakraProvider>
      <>

        {data.menu ?
          <Box>
            <Helmet>
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={getData(data.school, keys.LOGO_SMALL)}
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={getData(data.school, keys.LOGO_SMALL)}
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={getData(data.school, keys.LOGO_SMALL)}
              />
            </Helmet>
            <Switch>
              <Route
                exact
                path={Path.CAREER_FORM}
                render={() => <Career data={data} heading={false} />}
              />
              <Route
                exact
                path={Path.ONLINE_ADMISSION_FORM}
                render={() => <OnlineAdmission data={data} heading={false} />}
              />
              <Route
                exact
                path={Path.SCHOLARSHIP_ENQUIRY}
                render={() => <ScholarshipAdmission data={data} heading={false} />}
              />
              <Route
                path={"/"}
                render={() => <AllRoutes templateDesign={templateDesign} data={data} />}
              />
            </Switch>
          </Box>
          : null
        }
      </>
    </ChakraProvider>

  );
}

const AllRoutes = ({ data, templateDesign }) => {

  return (<>
    <Header templateDesign={templateDesign} data={data} />
    <Switch>



      {templateDesign == '0' ?
        <Route exact path="/" render={() => <Home data={data} />} /> :
        templateDesign == '1' ?
          <Route exact path="/" render={() => <Home2 data={data} />} /> :
          templateDesign == '3' ?
            <Route exact path="/" render={() => <Home4 data={data} />} /> :
            templateDesign == '5' ?
            <Route exact path="/" render={() => <Home5 data={data} />} /> :
            <Route exact path='/' render={() => <Home3 data={data} />} />}

      {/* <Route
      exact
      path="/pop"
      render={()=><WebsitePopup/>}/> */}

      {/* <Route exact path="/" render={() => <Home data={data} />} />
      <Route
        exact
        path="/home2"
        render={() => <Home2 data={data} />}
      /> */}
      <Route
        exact
        path={Path.ABOUTUS}
        render={() => <AboutUs data={data} />}
      />
      <Route
        exact
        path={Path.DESK_PATH}
        render={p => <Desk data={data} {...p} />}
      />
      <Route
        exact
        path={Path.AFFILIATION}
        render={() => <Affiliation data={data} />}
      />
      <Route
        exact
        path={Path.VISION_MISSION}
        render={() => <VisionAndMission data={data} />}
      />
      <Route
        exact
        path={Path.BIRTHDAYS}
        render={() => <Birthdays data={data} />}
      />
      <Route
        exact
        path={Path.TIMETABLE}
        render={() => <TimeTable data={data} />}
      />
      <Route
        exact
        path={Path.CURRICULUM}
        render={() => <Curriculum data={data} />}
      />
      <Route
        exact
        path={Path.ADMISSION_RULES}
        render={() => <AdmissionRules data={data} />}
      />
      <Route
        exact
        path={Path.FEE_DETAILS}
        render={() => <FeeDetails data={data} />}
      />
      <Route
        exact
        path={Path.ELIGIBILITY_CRITERIA}
        render={() => <EligibilityCriteria data={data} />}
      />
      <Route
        exact
        path={Path.GALLERY}
        render={() => <Gallery data={data} />}
      />
      <Route
        exact
        path={Path.ONLINE_ADMISSION}
        render={() => <OnlineAdmission data={data} heading={true} />}
      />
      <Route
        exact
        path={Path.KHELO_INDIA}
        render={() => <KheloIndia data={data} />}
      />
      <Route
        exact
        path={Path.CONTACT}
        render={() => <ContactUs data={data} />}
      />
      <Route
        exact
        path={Path.FACILITIES}
        render={() => <AllFacilities data={data} />}
      />
      <Route
        exact
        path={Path.DOWNLOAD}
        render={() => <Downloads data={data} />}
      />
      <Route
        exact
        path={Path.CBSE}
        render={() => <CbseDownloads data={data} />}
      />
      <Route
        exact
        path={Path.CAREER}
        render={() => <Career data={data} heading={true} />}
      />
      <Route
        exact
        path={Path.USEFUL_LINKS}
        render={() => <UsefulLinks data={data} />}
      />
      <Route
        exact
        path={Path.TC}
        render={() => <TransferCertificate data={data} />}
      />
      {/* <Route
        exact
        path={Path.MAP}
        render={() => <MapAddress data={data} />}
      /> */}
      <Route
        exact
        path={Path.FAQ}
        render={() => <FAQ data={data} />}
      />
      <Route
        exact
        path={Path.SCHOOL_UNIFORM}
        render={() => <SchoolUniform data={data} />}
      />
      <Route
        exact
        path={Path.CALENDAR}
        render={() => <SchoolCalendar data={data} />}
      />
      <Route
        exact
        path={Path.TEAM}
        render={() => <Team data={data} />}
      />


      <Route
        exact
        path={Path.ACHIEVEMENTS}
        render={() => <Achievements data={data} />}
      />
      <Route
        exact
        path={Path.MANDATORY_DISCLOSURE}
        render={() => <MandatoryDisclosure data={data} />}
      />

      <Route
        exact
        path={Path.COURSES}
        render={() => <Courses data={data} />}
      />
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
    <Footer data={data} />
  </>
  )
}

export default App;