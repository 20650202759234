import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  Textarea,
  theme,
  useTheme
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import {
  AiOutlineEnvironment,
  AiOutlineMail,
  AiOutlinePhone
} from 'react-icons/ai';
import MainContainer from '../components/MainContainer';
import getData, { keys } from '../data';
import $ from 'jquery';
import { getContactRequestAction } from '../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import STATUS from '../Constants';

const ContactUs = props => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { requestStatus } = useSelector(state => ({
    requestStatus: state.requestStatus
  }));

  useEffect(() => {
    if (requestStatus == STATUS.SUCCESS) $('#form')[0].reset();
  }, [requestStatus]);

  const submitContact = e => {
    e.preventDefault();
    dispatch(getContactRequestAction(new FormData(e.target)));
  };

  return (
    <MainContainer heading="Contact Us">
      <Text fontSize="2xl" textAlign="center" fontWeight="bold">
        How To Reach Us ?
      </Text>
      <Flex flexWrap="wrap">
        <ContactInfo
          type="phone"
          title="Call Us"
          info={
            getData(props.data.school, keys.PRIMARY_CONTACT) +
            ', ' +
            getData(props.data.school, keys.SECONDARY_CONTACT)
          }
        />
        <ContactInfo
          type="address"
          title="Address"
          info={
            getData(props.data.school, keys.ADDRESS) +
            ', ' +
            getData(props.data.school, keys.CITY) +
            ', ' +
            getData(props.data.school, keys.STATE) +
            ' - ' +
            getData(props.data.school, keys.PINCODE)
          }
        />
        <ContactInfo
          type="email"
          title="Email"
          info={getData(props.data.school, keys.EMAIL)}
        />
      </Flex>
      <br />
      <br />
      <Flex justifyContent="center">
        <Box textAlign="center" w={{ base: '100%', sm: '100%', lg: '40%' }}>
          <Text
            fontSize="2xl"
            textAlign="center"
            fontWeight="bold"
            color={theme.secondary}
          >
            Have Any <span style={{ color: '#5D6D7E' }}>Queries</span> ?
          </Text>
          <br />
          <form id="form" onSubmit={submitContact}>
            <Input type="text" required name="name" placeholder="Name" mb={2} />
            <Input
              type="hidden"
              value={getData(props.data.school, keys.ID)}
              name="school_id"
              placeholder="Name"
              mb={2}
            />
            <Input
              type="text"
              required
              name="father_name"
              placeholder="Father Name"
              mb={2}
            />
            <Input
              type="text"
              required
              name="phone"
              type="number"
              placeholder="Mobile Number"
              mb={2}
            />
            <Input type="email" name="email" placeholder="Email" mb={2} />
            <Textarea
              type="text"
              required
              name="message"
              placeholder="Message"
              mb={2}
            />
            <Button
              bg={theme.secondary}
              isLoading={requestStatus == 'FETCHING'}
              loadingText="Submitting"
              color="white"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Box>
      </Flex>
    </MainContainer>
  );
};

const ContactInfo = ({ type, title, info }) => {
  const theme = useTheme();
  return (
    <Flex
      flexDirection="column"
      lineHeight="1.5"
      justifyContent="center"
      alignItems="center"
      padding="50px"
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
    >
      <Box p={2}>
        {type == 'phone' ? (
          <AiOutlinePhone fontSize="40px" color={theme.secondary} />
        ) : type == 'address' ? (
          <AiOutlineEnvironment fontSize="40px" color={theme.secondary} />
        ) : type == 'email' ? (
          <AiOutlineMail fontSize="40px" color={theme.secondary} />
        ) : null}
      </Box>
      <Box>
        <Heading as="h4" size="md">
          {title}
        </Heading>
      </Box>
      <Text fontSize="md" textAlign="center">
        {info}
      </Text>
    </Flex>
  );
};

export default ContactUs;
