import React, { useState, useEffect } from "react";
import { Flex, Image, Box } from "@chakra-ui/react";
import getData, { keys } from "../../data";

const ImageBackgroundCarousel = (props) => {
  let slides = getData(props.props.data.school, keys.CAROUSEL);

  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesCount = slides.length;

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };
  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };
  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`,
  };

  const SLIDES_INTERVAL_TIME = 3000;
  const ANIMATION_DIRECTION = "right";

  useEffect(() => {
    const automatedSlide = setInterval(() => {
      ANIMATION_DIRECTION.toLowerCase() === "left" ? prevSlide() : nextSlide();
    }, SLIDES_INTERVAL_TIME);
    return () => clearInterval(automatedSlide);
  }, []);

  return (
    <Flex w="full" bg="#FFFFFF" alignItems="center" justifyContent="center">
      <Flex w="full" overflow="hidden">
        <Flex pos="relative" h={["300px", "600px"]} w="full" {...carouselStyle}>
          {slides.map((slide, sid) => (
            <Box key={`slide-${sid}`} flex="none" boxSize="full" shadow="md">
              <Image src={slide.image} boxSize="full" backgroundSize="cover" />
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ImageBackgroundCarousel;
