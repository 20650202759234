
import React from "react";
import {
  chakra,
  Box,
  Text,
  Image,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import _ from "lodash";
const Team = ({ props }) => {
  let departments = props?.data?.school.departments?.data
  let teamVisibility=props?.data?.school.features?.includes(24)
  let team = _.map(departments, department => {
    let staff = _.chain(department.designations.data).flatMap(designation => _.chain(designation.staff.data)
      .filter(staff => staff.website == 1)
      .map(staff => Object.assign(staff, { designation, department }))
      .value()).value()
    return { department, staff }



  })
  team = _.flatMap(team, d => d.staff)

  return (
    <>
      {
        team && team.length > 0 && teamVisibility ?
          <>
            <Text fontSize={["3xl", "4xl", "4xl", "4xl"]} fontWeight="bolder" textAlign="center"  >Our Team</Text>
            <Text fontSize={["1xl", "2xl", "1.2em", "1xl"]} textAlign="center"  >Our Heroes who constantly work hard to help you achieve your dream</Text>
            <Flex flexWrap="wrap" p="4em" justify='center' >
              {team.map(person =>
                <Person name={person.user.data.name} designation={person.designation.name} image={person.user.data.avatar.image} department={person.department.name} />
              )}
            </Flex>
          </>
          : null
      }


    </>
  )
}

export default Team




const Person = ({ name, designation, image, department }) => {
  const MotionBox = motion(Box);
  return (
    <Flex
      bg={useColorModeValue("#F9FAFB", "gray.600")}
      p={[5]}
      flexWrap='wrap'
      alignItems="center"
    >
      <MotionBox
        w="16em"
        bg={useColorModeValue("white", "gray.800")}
        shadow="lg"
        rounded="lg"
        overflow="hidden"
        mx="auto"
        whileHover={{ scale: 1.1, opacity: '0.9' }}
        whileTap={{ scale: 0.9 }}
      >
        <Image
          w="full"
          h='10em'
          fit="cover"
          src={image}
          fallbackSrc='../images/img_avatar2.png'
          alt="avatar"
        />

        <Box py={5} textAlign="center">
          <Text
            display="block"
            fontSize={['lg', "xl"]}
            color={useColorModeValue("gray.800", "white")}
            fontWeight="bold"
          >
            {name}
          </Text>
          <chakra.span
            fontSize="sm"
            fontWeight='bold'
            color={useColorModeValue("gray.700", "gray.200")}
          >
            {designation}

          </chakra.span>
          <Text
            fontSize="sm"
            fontWeight='bold'
            color={useColorModeValue("gray.700", "gray.200")}
          >
            {department}
          </Text>
        </Box>
      </MotionBox>
    </Flex>
  );
};



// 1. Create a custom motion component from Box
// const MotionBox = motion.custom(
//   forwardRef((props, ref) => {
//     const chakraProps = Object.fromEntries(
//       // do not pass framer props to DOM element
//       Object.entries(props).filter(([key]) => !isValidMotionProp(key)),
//     )
//     return <Person />
//   }),
// )

// // 2. You'll get access to `motion` and `chakra` props in `MotionBox`
// function Example() {
//   return (
//     <MotionBox
//       boxSize="40px"
//       bg="red.300"
//       drag="x"
//       dragConstraints={{ left: -100, right: 100 }}
//       whileHover={{ scale: 1.1 }}
//       whileTap={{ scale: 0.9 }}
//     />
//   )
// }