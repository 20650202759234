import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

const Loader = props => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Box className="lds-roller">
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
      </Box>
    </Flex>
  );
};

export default Loader;
