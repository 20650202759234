import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import Loader from './Loader';
import { BiError } from 'react-icons/bi';
import STATUS from '../Constants';

const ErrorChecker = ({ status, children }) => {
  return (
    <Box>
      {status === STATUS.FETCHING ? (
        <Loader />
      ) : status === STATUS.FAILED ? (
        <Flex color="#FF5722" alignItems="bottom">
          <Box pr={2}>
            <BiError fontSize="23px" />
          </Box>
          <Text fontSize="l"> Could not load data</Text>
        </Flex>
      ) : status === STATUS.SUCCESS ? (
        children
      ) : null}
    </Box>
  );
};

export default ErrorChecker;
