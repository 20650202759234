import { Box, Container, Flex, Image, Text, useTheme } from '@chakra-ui/react';
import React, { useState } from 'react';
import MainContainer from '../components/MainContainer';
import getData, { keys } from '../data';
import _ from 'lodash';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { AiOutlineZoomIn } from "react-icons/ai";

const Gallery = props => {
  const [lightboxOpen, setLightBox] = useState(false);
  const [images, setImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openGallery = (images, isOpen) => {
    setLightBox(isOpen);
    setPhotoIndex(0);
    setImages(images);
  };

  return (
    <MainContainer heading="Picture Gallery">
      <Flex flexWrap="wrap" p={{base:0, md:10}} justify= 'center'>
        {getData(props.data.school, keys.GALLERY)
          .filter(val => val.images && val.images.length)
          .map((val, i) => (
            <Box
              key={i}
              w={{ base: '100%', sm: '100%', lg: '33%' }}
              px={4}
            >
              <Container borderRadius='5px' p ={0}
                className="birthday-card" pos='relative'
                background="white" cursor='pointer'
                my={8}
                onClick={() => openGallery(val.images, true)}
              >
                <Box p={2} className='imgContainer'>
                  <Image w='100%' h='270px' className='galleryImg' objectFit='cover'
                    src={val.images[0].image}
                    alt="Card image"
                  />
                  <Flex alignItems='center' className='middle'>
                    <Flex flexDirection='column' alignItems='center' color='white'>
                      <AiOutlineZoomIn fontSize={70}/>
                    </Flex>
                  </Flex>
                </Box>
                <Box
                  className="birthday-card-container"
                  textAlign="center"
                  p={2}
                >
                  <Text
                    // color={theme.secondary}
                    fontSize="2xl"
                  >
                    {val.name}
                  </Text>
                  <Text color='gray.500'>
                    { val.images.length + ' photos'}
                  </Text>
                </Box>
              </Container>
            </Box>
          ))}
      </Flex>
      {lightboxOpen && (
        <Lightbox
          imagePadding={50}
          mainSrc={images[photoIndex].image}
          nextSrc={images[(photoIndex + 1) % images.length].image}
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length].image
          }
          onCloseRequest={() => openGallery([])}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </MainContainer>
  );
};

export default Gallery;
