import _ from 'lodash';

export const Facilities = [
  {
    id: 1,
    title: 'Transport Facilities',
    image: 'images/facilities/school-bus.svg',
    content:
      'The school has its own vans for taking students to different places for participating in various activities . School provides transport facility to students across the city. To ensure total safety of children the vans have attendant on board . At present the school has a fleet of 4 vans. It will be the responsibility of the parents to ensure that their children reach the pick-up points well in time, as the transport will not be delayed if the child is not available at the pick-up point. In case of any problem regarding vans the Transport Incharge may be contacted.'
  },
  {
    id: 2,
    title: 'Library Facilities',
    image: 'images/facilities/library.svg',
    content:
      'The library in our school is being made the most attractive place in the school so that students will be naturally drawn to it. Research shows that reading for enjoyment is more important for children’s educational success that their family’s socio-economic status. To develop kids’ interest in reading, we have an interesting collection of story books like PANCHTANTRA , MORAL STORY BOOKS, GRANDMA’S STORIES,FAIRY TALES like Cindrella, Snow white and seven Dwarfs, etc. The way to get children reading is to leave the library door open and let them read anything and everything they want.'
  },
  {
    id: 3,
    title: 'Computer Labs',
    image: 'images/facilities/desktop.svg',
    content:
      'Computer education in school plays important role in students’ career development. Almost everywhere computers are used at large. This is the need of the hour to educate students in computer education.'
  },
  {
    id: 4,
    title: 'Sports Facilities',
    image: 'images/facilities/basketball.svg',
    content:
      'Playing sports can help students increase their self-esteem and develop more confidence . A sportsman knows that winning and losing are parts of sports, so maintaining , a sportsmanship is the best way of mental strength and success.'
  },
  {
    id: 5,
    title: 'Art & Craft',
    image: 'images/facilities/palette.svg',
    content:
      "And it's not just about crafts. We provide space, equipment and – crucially – the technical help to make full use of this for students across Painting, Drawing and Printmaking, Photography, Film, Graphic Design, Games, Illustration, Fashion, and more. And we are firm believers in an open door policy. Students across all areas of the school can use equipment across campus. We encourage fashion designers to work with photographers; film-makers can collaborate with costume; and lots, lots more."
  },
  {
    id: 6,
    title: 'Audio Visual Room',
    image: 'images/facilities/video-camera.svg',
    content:
      'To enhance the teaching learning experience and accelerate the reading, writing, and listening skills our school has a spacious and air conditioned Audio Visual Room.  '
  },

  {
    id: 7,
    title: 'Cafetaria',
    image: 'images/facilities/apple.svg',
    content:
      'The latest addition is a sophisticated ‘Cafeteria – II’ along with a dinning hall in the Classroom Block with a built up area of 374 m2. It also provides best food and snacks to the students and staff.'
  },

  {
    id: 8,
    title: 'Smart Classrooms',
    image: 'images/facilities/projector.png',
    content:
      'Studies have found that Smart Class can make a huge difference in the learning habits of students. Students have this tremendous ability to work skillfully with various devices. What students used to achieve with half a dozen textbooks is now made available with just a touch, in the form of a tablet. It is a transformation of previous methodology, used in traditional schools, from ‘one to many’ to ‘one to one’ guided system of learning.'
  },
  {
    id: 9,
    title: 'Internet Facilities',
    image: 'images/facilities/internet.png',
    content:
      'The network backbone is designed with dedicated Fiber Optics cable and the internal network is equipped with Cisco 2900 Router Series and Cisco 2960 Switches. A dedicated 1 Gbps internet main line supports the academic infrastructure round the clock and provide access to the resources on the Internet. The academic block is internally connected through Wi-Fi as well as wired LAN. The hostel is connected to the academic block through RF. The hostel also has 24×7 network access through Wi-Fi .Both the academic block and the hostel area use D-link DWL – 3200 secured Wi-Fi connectivity.'
  },
  {
    id: 10,
    title: 'Health Care',
    image: 'images/facilities/health-care.svg',
    content:
      'A healthy body consist of a healthy mind, spirit and soul. An infinite flight of mind requires a healthy leap of physical fitness too. Ensuring it our school provides a fulfilling and throbbing ground experience to the students. Variety of sports activities, mental training and concentration prepares a sound body system to tackle all possible hurdles in the world outside.'
  },
  {
    id: 11,
    title: 'Labs',
    image: 'images/facilities/chemistry.svg',
    content:
      'The laboratory is the real place for learning science like physics, Biology and chemistry through practical experiments. Our school keep fairly enough stock of the apparatuses and tools required to perform the experiments. Each child is given individual attention keeping in mind the fact it is an age of rapidly expanding information technology, we have made computer science compulsory subject from class 1 upwards. '
  },
  {
    id: 12,
    title: 'Schoollog App',
    image: 'images/facilities/schoollogapp.png',
    content:
      "Schoollog is a hosted solution which is highly beneficial to educational institutions and allows them to monitor the overall working of the whole institution and manage its day-to day operations through the web or mobile app, anytime from anywhere in the world. It nurtures the interaction between school, teachers, students and parents in the most effective way. It fulfills the school's need for an easy, scalable and economical solution, which is future-proof and can integrate with upcoming technologies."
  },
  {
    id: 13,
    title: 'Picnic & Education Outings',
    image: 'images/facilities/food-basket.svg',
    content:
      'Excursions at school extend education beyond the classroom. Students and teachers have visited numerous places around our school as well as distant places and find the experience of living and travelling together a rich opportunity for learning and in becoming familiar with environmental issues, with our historical heritage and with important cultural centers. The school places a lot of importance on supplementing classroom learning with visits to places of historical, cultural and geographical importance. Children of primary classes are taken out for picnics to spots in and around Kurukshetra, senior students are taken for educational tours and sports tour to places like Delhi, Punjab, Haryana, etc. International tours and visits are also organized to countries like Nepal, Bhutan & Thailand, etc.'
  },
  {
    id: 14,
    title: 'Music & Dance',
    image: 'images/facilities/dance.png',
    content:
      'The school has a full of fledged music and dance faculty with a variety of instruments. Both music and dance a vital factors in creating harmony in the students life. They are contributory ingredients in maintaining the emotional health of a student. Music as well as dance absorb stress and make a person feel relaxed regularly we hold the competitions among the students like singing completion , solo dance competition group dance, drama and others too to enhance the inner abities of our students.'
  },
  {
    id: 15,
    title: 'Online Fees Submition',
    image: 'images/facilities/transfer.png',
    content:
      'Among the current Indian digital environment, E-Payments is becoming the chosen way to pay. It is safe and convenient, users are moving to digital option for multiple choices of payment – through credit or debit cards, bank accounts and e-Wallets. With Internet Payments is becoming one of the most user-friendly payment options in the recent times it is required for the parents also to make payment online for their ward’s school fee.'
  }
];

export const getFacilities = (ids, customFacilities = null) => {
  const facis = _.map(ids, id => {
    let fa = _.find(Facilities, f => f.id == id)
    if(customFacilities && customFacilities.length) {
      let cfa = _.find(customFacilities, f => f.id == id)
      if(cfa) {
        fa.title = cfa.title
        fa.content = cfa.description
      }
    }
    return fa
  });
  return facis;
};
