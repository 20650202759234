import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorChecker from '../common/ErrorChecker';
import MainContainer from '../components/MainContainer';
import { getSubjects, submitCareerForm } from '../redux/actions';
import STATUS, { printHelper, PRINT_TYPE } from '../Constants';
import _ from 'lodash';
import  Select from 'react-select'
import { CustomDatePicker } from '../helper';
import getData, { keys } from '../data';
import $ from 'jquery'
import { ErrorAlert } from '../common/CommonAlerts';

const Career = props => {
  const dispatch = useDispatch();
  const { subjectsStatus, subjectsList, careerFormStatus } = useSelector(state => ({
    subjectsList: state.subjects,
    subjectsStatus: state.subjectsStatus,
    careerFormStatus: state.careerForm
  }));
  useEffect(() => {
    dispatch(getSubjects(props.data.school.id)) 
  }, []);

  const submitForm = e => {
    e.preventDefault();
    let formData = new FormData(e.target)
    if((props.data.school.id == '2Qz1RxYANMxZmXbP' || props.data.school.id == '2lYZyLQB3VEDnRMA') && !formData.get('center')) {
      ErrorAlert('Center is Required')
      return
    }
    dispatch(submitCareerForm(formData));
  };

  const resetForm = () => {
    document.getElementById('enquiryForm').reset();
  }


  return (
    <MainContainer heading={props.heading ? "Career" : undefined}>
      <br />
      <ErrorChecker status={subjectsStatus}>
        {subjectsStatus == STATUS.SUCCESS ? (
          <form id="enquiryForm" onSubmit={e => submitForm(e)}>
            <Box>
              <Text fontSize="2xl" color="#2E4053" mb={4}>
                Personal Details
              </Text>
              <Flex flexWrap="wrap">
                <CustomInput title="Applicant Name" name="name" required />
                <CustomInput title="Father Name" name="father_name" required />
                <DateOfBirth title="Date of Birth" name="dob" />
                <CustomInput title="Email" type="email" name="email" />
                <Gender title="Gender" />
                <CustomInput title="Contact 1" type='number' name="contact1" />
                <CustomInput title="Contact 2" type='number' name="contact2" />
                {(props.data.school.id == '2Qz1RxYANMxZmXbP' || props.data.school.id == '2lYZyLQB3VEDnRMA') ?
                <Box
                  key={'center'}
                  w={{ base: '100%', sm: '100%', lg: '33.33%' }}
                  pr={8}
                  pb={4}
                >
                  <FormControl>
                    <FormLabel>
                      {`Center`} {<span style={{ color: 'red' }}>*</span>}
                    </FormLabel>
                  <Select
                      placeholder="Center"
                      name='center'
                      options={[
                        {'value': 'Sikar', 'label': 'Sikar',},
                        {'value': 'Alwar', 'label': 'Alwar',},
                        {'value': 'Jaipur', 'label': 'Jaipur',}
                      ]}
                      // onChange={selected => selectCenter(selected)}
                  >

                  </Select>

                  </FormControl>
                </Box>
                :
                null
                    }

                <SelectSubject subjectssList={subjectsList} />
              </Flex>
            </Box>
            <br />
            <br />
            <Box>
              <Text fontSize="2xl" color="#2E4053" mb={4}>
                Education Details
              </Text>
              <Flex flexWrap="wrap">
                <CustomInput title="Qualification" name="qualification" />
                <CustomInput title="Institute" name="institute" />
                <CustomInput title="Board" name="board" />
                <CustomInput title="Marks/Grade" name="marks" />
              </Flex>
            </Box>
            <br />
            <br />
            <Box>
              <Text fontSize="2xl" color="#2E4053" mb={4}>
                Experience
              </Text>
              <Flex flexWrap="wrap">
                <Period title="Period" />
                <CustomInput
                  title="Organisation & Address"
                  name="organisation_address"
                />
                <CustomInput title="Designation" name="designation" />
                <PersonalAchivement
                  title="Personal Achivement (if any)"
                  type="file"
                  name="personal_achievements"
                />
                <CustomInput
                  title="Upload Resume"
                  type="file"
                  name="resume"
                />
                <CustomInput type="hidden" name="school_id" defaultValue={getData(props.data.school, keys.ID)} />
              </Flex>
            </Box>
            <Button type="submit" colorScheme="blue">
              Submit
            </Button>&nbsp;&nbsp;
            {careerFormStatus == STATUS.SUCCESS ? 
              <span>
                <Button onClick={() => printHelper(PRINT_TYPE.CAREER_FORM, props.data.careerPrintInfo)} colorScheme="blue">
                  Print Form
                </Button>&nbsp;&nbsp;
                <Button colorScheme="green" onClick={resetForm}>
                  Reset
                </Button>
              </span>
              : null
            }
          </form>
        ) : null}
      </ErrorChecker>
    </MainContainer>
  );
};

const Period = ({ title }) => {
  const months = [
    { label: 'None', value: 0 },
    { label: 'One', value: 1 },
    { label: 'Two', value: 2 },
    { label: 'Three', value: 3 },
    { label: 'Four', value: 4 },
    { label: 'Five', value: 5 },
    { label: 'Six', value: 6 },
    { label: 'Seven', value: 7 },
    { label: 'Eight', value: 8 },
    { label: 'Nine', value: 9 },
    { label: 'Ten', value: 10 },
    { label: 'Eleven', value: 11 }
  ];

  const [selectedMonth, setMonth] = useState('');
  const [year, setYear] = useState('');

  const selectMonth = month => {
    setMonth(month);
  };

  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>Period</FormLabel>
        <Flex justifyContent="space-between">
          <Box w={{ base: '100%', sm: '100%', lg: '48%' }}>
            <Input min={0}
              type="number"
              placeholder="Year"
              onChange={e => setYear(e.target.value)}
            />
          </Box>
          <Box w={{ base: '100%', sm: '100%', lg: '48%' }}>
            <Select options={months}
              placeholder="Month"
              onChange={selected => selectMonth(selected)}
            />
          </Box>
        </Flex>
        <Input
          name="experience"
          type="hidden"
          value={
            year && selectedMonth
              ? _.add(parseInt(year) * 12, parseInt(selectedMonth.value))
              : year
                ? parseInt(year) * 12
                : selectedMonth ? parseInt(selectedMonth.value) : null
          }
        />
      </FormControl>
    </Box>
  );
};

const SelectSubject = ({ title, subjectssList}) => {
  const [selectedSubjects, selectSubject] = useState([])
  const options = subjectssList.map(subject => ({value:subject.id, label:subject.name}))
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>Applying For Subjects</FormLabel>
        <Select placeholder='Select Subjects' closeMenuOnSelect={false} options={options} isMulti onChange={selected => selectSubject(selected)}/>
        {selectedSubjects
          ? selectedSubjects.map((val, i) => (
            <Input name={`subject_apply[${i}]`} key={i} type="hidden" value={val.label} />
          ))
          : null
        }
      </FormControl>
    </Box>
  );
};

const PersonalAchivement = ({ title, width, name }) => {
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: width || '66.66%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>{title}</FormLabel>
        <Textarea rows={3} name={name} placeholder={title} />
      </FormControl>
    </Box>
  );
};

const Gender = ({ title }) => {
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>
          {title} <span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <RadioGroup required>
          <Stack spacing={4} direction="row">
            <Radio name="gender" value="male">
              Male
            </Radio>
            <Radio name="gender" value="female">
              Female
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const DateOfBirth = ({ title, name }) => {
  const [dob, setDob] = useState('')
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>
          {title} <span style={{ color: 'red' }}>*</span>
        </FormLabel>
        <CustomDatePicker getDate={date => setDob(date)}/>
        <Input type='hidden' required name={name} value={dob} />
      </FormControl>
    </Box>
  );
};

const CustomInput = ({ title, type, width, placeholder, required, name, defaultValue }) => {
  return (
    <Box
      key={title}
      w={{ base: '100%', sm: '100%', lg: width || '33.33%' }}
      pr={8}
      pb={4}
    >
      <FormControl>
        <FormLabel>
          {title} {required && <span style={{ color: 'red' }}>*</span>}
        </FormLabel>
        <Input
          type={type || 'text'}
          name={name}
          required={required}
          placeholder={placeholder || title}
          defaultValue={defaultValue}
          min={0}
        />
      </FormControl>
    </Box>
  );
};

export default Career;
