import React from "react";
import { AspectRatio } from "@chakra-ui/layout";
const Map = ({ data }) => {
	let urlData = data.data.school.website.data?.map_embed_link
	const regex = /src=[^\s]+/gm;
	let url;
	let m;

	while ((m = regex.exec(urlData)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === regex.lastIndex) {
			regex.lastIndex++;
		}

		// The result can be accessed through the `m`-variable.
		m.forEach((match, groupIndex) => {
			url = match
			// console.log(`Found match, group ${groupIndex}: ${match}`);
		});
	}
	url = url.replace('src=', "").replace('"', '')

	return (
		<>
			{
				url ?
					<div>
						<AspectRatio maxH="500px" ratio={16 / 9}>
							<iframe src={url} >

							</iframe>
						</AspectRatio>


					</div>
					: null
			}
		</>

	);
};


export default Map;
