import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
  useTheme
} from '@chakra-ui/react';
import React from 'react';
import _ from 'lodash'
import { getFacilities } from '../data/Facilities';
import getData, { keys } from '../data';
import { NewLink } from '../data/Path';
import { useHistory } from 'react-router-dom';

const HomeFacilities = props => {
  const theme = useTheme();

  const bgColors=[
    '174deg, #C4D9F5 0%, #C4D9F5 0%, #C4D9F5 100%',
    '180deg, #E6DDF3 0%, #E6DDF3 0%, #E6DDF3 100%',
    '174deg, #D1D4F5 0%, #D1D4F5 0%, #D1D4F5 100%',
    '174deg, #F5D6D7 0%, #F5D6D7 0%, #F5D6D6 100%',
    '174deg, #D5F5E3 0%, #D5F5E3 0%, #D5F5E3 100%',
  ]
  return (
    <Box w="100%" py={20} 
    // bg="transparent linear-gradient(174deg, #F6F7F9 0%, #F6F7F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box"
    >
      <Heading textAlign="center" color={theme.primary} size="xl">
        Facilities
      </Heading>
      <br />
      
      <Flex mx={{base:1, md:6, lg:10}} flexWrap="wrap" color="#252B33" justify={'center'}>
        {_.map(_.take(getFacilities(getData(props.data.school, keys.FACILITIES)), 5), (f, i) => {
          const fImage = _.find(
              getData(props.data.school, keys.FACILITIES_IMAGES),
              val => val.id == f.id,
            );
          return(<Facility key={i} bgColor={`linear-gradient(${bgColors[i]})`} image={fImage && fImage.image ? fImage.image : f.image} data={f} />)
        })}
      </Flex>
    </Box>
  );
};

const Facility = ({ bgColor, data, image }) => {
  const theme = useTheme();
  const history = useHistory()
  return (
    <Flex
      p={3}
      flexDirection="column"
      my={4}
      mx={3}
      w={{ base: '100%', sm: '100%',md:'30%', lg: '30%',xl:'22%' }}
      minHeight="550px"
      borderRadius="10px"
      bg={bgColor}
    >
      <Flex justifyContent='center' height={{base:100, md:160}}>
        <Image
          src={image}
          w={{base:120, md:160}}
          borderRadius='10px'
        />
      </Flex>
      <br/>
      <Text textAlign="center" color={theme.secondary} fontWeight="bold" fontSize="xl" my={2}>
        {data.title}
      </Text>
      <Container
        fontSize="15px"
        textAlign="center"
        className="facilities-text-ellipsis"
      >
        {data.content}
      </Container>
      <Spacer />
      <Box textAlign="center">
        <br/>
        <Button
          onClick={() => NewLink(history, `/facilities#section-${data.id}`)}
          mb={8}
          variant="link"
          textDecoration="underline"
          size="lg"
          fontWeight="normal"
          color={theme.secondary}
        >
          Read More
        </Button>
      </Box>
    </Flex>
  );
};

export default HomeFacilities;
