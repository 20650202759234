import React, { Component, useEffect } from 'react';
import { PRINT_TYPE } from '../Constants';
import _ from 'lodash';
import { Box } from '@chakra-ui/react';
import AdmissionFormPrint from '../components/AdmissionFormPrint';
import PrintCareerForm from '../components/PrintCareerForm';
import CustomEnquiryFormPrint from '../components/CustomEnquiryFormPrint'

const GenPrint = (props) => {

  useEffect(() => {
    window.focus();
    setTimeout(() => window.print(), 200);
  }, [])

  return (
    <Box>
      {props.type == PRINT_TYPE.ADMISSION_FORM ? (
        <Box>
          <AdmissionFormPrint data={props.data} />
        </Box>
      ) : props.type == PRINT_TYPE.CAREER_FORM ? (
        <Box>
          <PrintCareerForm data={props.data} />
        </Box>
      ) : props.type == PRINT_TYPE.ADMISSION_FORM_CUSTOM ? (
        <CustomEnquiryFormPrint data={props.data} />
      ) :
        null}
    </Box>
  )
}

export default GenPrint
