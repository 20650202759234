import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
import getData, { convertHTMLString, keys } from '../data';
import _ from 'lodash'

const FAQ = props => {
  return (
    <MainContainer heading="FAQ">
      <Box>
            {_.sortBy(getData(props.data.school, keys.FAQS), val => val.order).map(val => 
                <Box key={val.id}>
                    <Text fontWeight='bold'>Que: {val.title}</Text>
                    <Flex>
                        <Text fontWeight='bold'>Ans: </Text>
                        <Text>{val.description}</Text>
                    </Flex>
                    <br/>
                </Box>
            )}
      </Box>
    </MainContainer>
  );
};

export default FAQ