import React, { useEffect } from "react";
import { Flex, Box, chakra, Spacer, Button, useBreakpointValue, useColorModeValue, Text, HStack, Avatar, Image, Center } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import getData, { convertHTMLString, keys } from '../../data'
import Carousel from "react-elastic-carousel";
import { AiFillCheckCircle, AiOutlineCalendar } from 'react-icons/ai';
import { Link } from "react-router-dom";
import $ from 'jquery'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const News_Events5 = (props) => {

    const buttonSize = useBreakpointValue(["xs", "sm", "md", "lg"]);

    const calender = useSelector(state => state.school.website?.data.calender)
    const announcements = useSelector(state => state.school.website?.data.announcements)
    function dateFormat(date) {
        let newData = new Date(date)
        return newData.toLocaleDateString("en-US", { day: "2-digit", month: "short" })
    }
    const breakPoints = [
        { width: 200, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 2 },
    ];


    const dateTransform = (date) => {
        let newDate = new Date(date)
        return newDate ? newDate.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" }) : ''
    }

    useEffect(() => {
        $('#marquee1').hover(
            function (event) {
                this.stop();
            },
            function () {
                this.start();
            },
        );
    }, [])

    const renderNextButton = ({ isDisabled }) => {
        return <ArrowForwardIcon bg="blackAlpha.500" color={"white"} borderRadius={50} fontSize={26} position="absolute" right="0" top="150" />
    };

    const renderPrevButton = ({ isDisabled }) => {
        return <ArrowBackIcon bg="blackAlpha.500" color={"white"} borderRadius={50} fontSize={26} position="absolute" left="0" top="150" />
    };

    return (

        <>
            {
                ((announcements && announcements.length > 0) || (calender && calender.length > 0)) ?

                    <>
                        <Box p="2em" bg="#37383A" w="full" >
                            <chakra.h2
                                mx="20px"
                                fontSize={["2xl", "4xl", "2xl", "3xl"]}
                                fontWeight="extrabold"
                                letterSpacing="tight"
                                lineHeight="shorter"
                                color="white"
                            >
                                <chakra.span display="block">
                                    Annoucement
                                </chakra.span>
                            </chakra.h2>
                            <chakra.h5
                                mx="20px"
                                fontSize={["lg", "lg", "lg", "md"]}
                                letterSpacing="tight"
                                lineHeight="shorter"
                                color="white"
                            >
                                <chakra.span
                                    display="block"
                                    mx="auto"
                                    textTransform="uppercase"
                                >
                                    Our learn based program
                                </chakra.span>
                            </chakra.h5></Box>
                        <Flex
                            py={["1em", "1em", "1em", "3em"]}
                            mx="0px"
                            w="full"
                            bg="#FAFAFA"
                            flexWrap="wrap"
                            px={{ base: 2, lg: 8 }}
                        >

                            {/* <chakra.h4
                mx="20px"
                fontWeight="bold"
                fontSize={["lg", "lg", "xl", "xl"]}
                letterSpacing="tight"
                lineHeight="shorter"
                color="black"
            >
                <chakra.span
                    display="block"
                    mx="auto"
                    textTransform="uppercase"
                >
                    Announcements
                </chakra.span>
            </chakra.h4> */}
                            {announcements && announcements.length > 0 ?
                                <Box w={["full", "70%", "60%", "60%", "60%", "70%"]} my={["0.5em", "1.5em"]} pt="1em" >
                                    <AliceCarousel autoPlay autoPlayInterval={2000} infinite={true} responsive={{ 0: { items: 1 }, 1024: { items: 3 } }} mouseDragEnabled
                                        renderPrevButton={renderPrevButton}
                                        renderNextButton={renderNextButton}
                                    >
                                        {announcements.map((slide) => (
                                            <Center>
                                                <Flex bg="white" flexDirection='column' as={Link} target="_blank" onClick={() => window.open(slide.link)} cursor="pointer" maxW="sm" _hover={{
                                                    textDecoration: 'none',
                                                    boxShadow: 'lg'
                                                }} borderWidth="1px" width='250px' height='350px' borderRadius="lg" overflow="hidden">
                                                    <Image fallbackSrc="/images/announcement.png" src={slide.image} h='200px' alt={'No Image'} />

                                                    <Box p="6" >
                                                        <Box d="flex" alignItems="center" >
                                                            <AiOutlineCalendar px="2" />
                                                            <Text color="gray.500"
                                                                fontWeight="semibold"
                                                                letterSpacing="wide"
                                                                fontSize="sm"
                                                                textTransform="uppercase"
                                                                ml="2">
                                                                {slide.date ? dateTransform(slide.date) : ' - '}</Text>
                                                        </Box>
                                                        <Text
                                                            mt="1"
                                                            fontWeight="semibold"
                                                            as="h4"
                                                            lineHeight="tight"
                                                            noOfLines={3}
                                                            textDecoration='none'
                                                        >
                                                            {slide?.title}
                                                        </Text>
                                                    </Box>
                                                </Flex>
                                            </Center>
                                        ))}
                                    </AliceCarousel>
                                </Box>
                                : null
                            }
                            <Spacer />
                            {/* {calender && calender.length > 0 ?
                                <Flex
                                    alignItems="stretch"
                                    flexDirection="column"
                                    borderRadius="10px"
                                    boxShadow="0px 0px 3px #28282838"
                                    bg="#F6F7F9"
                                    height="450px"
                                    p="5"
                                    mt="5"
                                >
                                    <Box color="#22355D" fontWeight="bold">
                                        {' '}
                                        Latest NEWS & Events
                                    </Box>
                                    <marquee direction="up" id="marquee1" height={450}>
                                        {getData(props?.data?.school, keys.ANNOUNCEMENTS).map((d, i) =>
                                            <Flex mb="4" key={i} color="#252B33" fontSize="14px">
                                                <Box pr={4}>
                                                    <AiFillCheckCircle color="#85C1E9" fontSize="18px" />
                                                </Box>
                                                <Box dangerouslySetInnerHTML={convertHTMLString(d.text)} />
                                            </Flex>
                                        )}
                                    </marquee>
                                </Flex>
                                : null
                            } */}
                             {
                                calender && calender.length > 0 ?


                                    <Box mx={["1em", "1em", "auto", "1em"]} p="1em" bg="#EEEEEE" textAlign="center" w={["full", "10em", "15em", "25em"]} >
                                        <Text fontWeight="extrabold"
                                            letterSpacing="tight" fontSize={["2xl", "4xl", "2xl", "2xl"]} mb="1em">Notice Board / Events</Text>
                                        <marquee
                                            direction="up"
                                            id="marquee1"
                                            // style={{ background: 'rgb(244, 246, 246)' }}
                                            height="250px"
                                        >
                                            {/* <marquee onMouseOver={(e) => console.log(e)} onMouseLeave={() => console.log('mouse left')} behavior="scroll" direction="up" height={['250px', '400px']}> */}
                                            {
                                                calender.map(element =>
                                                    <HStack my="1em" borderRadius="0.3em" bg="white" mx={4} spacing="0">
                                                        <Box m="0" textAlign="center" color="white" w="40%" p="0.5em" alignSelf="center" bg="#43C065">

                                                            {
                                                                (element.startDate == element.endDate) ?
                                                                    <Text fontSize={["0.8em", "sm"]} >{dateFormat(element.startDate)}</Text> :
                                                                    <Text fontSize={["0.8em", "sm"]} >{dateFormat(element.startDate)}-{dateFormat(element.endDate)}</Text>

                                                            }

                                                        </Box>
                                                        <Box p="0.5em" m="0">{element.title}</Box>
                                                    </HStack>

                                                )
                                            }
                                        </marquee>
                                    </Box>


                                    : null
                            }

                        </Flex></>
                    : null
            }
        </>
    );
};

export default News_Events5;
