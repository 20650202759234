import React from "react";
import {
  chakra,
  Box,
  Text,
  Flex,
  useColorModeValue,
  Button,
  useTheme,
  Spacer
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {TimeIcon,ArrowRightIcon} from "@chakra-ui/icons"

import { convertHTMLString } from "../../data";
import _ from "lodash";
const Courses = ({ props }) => {

  let courses = _.filter(props.data.school.standards?.data, course => course.website == 1)
  return (
    <>
      {
        courses && courses.length > 0 ?
    <Box p={["1em", "1em", "1em", "2em"]} my="2em" bg="#f7f7f7">
            <Text fontSize={["3xl", "4xl", "4xl", "4xl"]} fontWeight="bolder" textAlign="center"  >Explore Top Courses</Text>
            <Text fontSize={["1xl", "2xl", "1.2em", "1xl"]} textAlign="center"  >Providing the best learning experience</Text>
      <Flex flexWrap="wrap" p={['2em', '4em']} justify='center' >
        {
          courses && courses.map((course, key) =>
                  <Course id={key} key={key} name={course.name} description={course.description} price={course.price} status={course.status} type={course.type} />
          )
        }
      </Flex></Box>
          : null
      }
    </>

  )
}

export default Courses




const Course = ({ name, description, id, price, status, type }) => {
  const theme = useTheme()
  return (
    <Flex
      bg={useColorModeValue("#F9FAFB", "gray.600")}
      m={[1, 4]}
      w={["20em", "16em", "16em", "18em"]}
      boxShadow="lg"
      alignItems='stretch'
      _hover={{
        boxShadow: "2xl"
      }}
    >
      <Flex
        mx="auto"
        justifyContent='space-between'
        direction='column'
        py={['1em']}
        position='relative'
        px={[4, 4, 4, 8]}
        w={["20em", "16em", "20em", "20em"]}
        bg={useColorModeValue("white", "gray.800")}
        shadow="lg"
        rounded="lg"
      >
        <Box>
        <Text>{type} Course</Text>
          <Box h={['2px', '5px']} my={['5px', '1em']} w='70%' bg='gray.100' >&nbsp;</Box>
          <Text fontWeight='bolder' fontSize={['xl', '2xl', '2xl', 'lg']} >{name}</Text>
          {
            type && type.length > 0 ? <Flex my={['5px', '1em']} >
              <Text fontSize={['sm', 'md']} fontWeight='bold' >Mode </Text> : <Text> {type}</Text>
            </Flex> : null

          }
       <Text dangerouslySetInnerHTML={convertHTMLString(description)} /> 
        </Box>
        <Box justifySelf='flex-start'  >
     
          {
            status && status.length > 0 ? <Box maxW={["20em", "16em", "16em", "18em"]} whiteSpace='break-spaces' textAlign='left' justifyContent='flex-start' py='1em' fontSize='xs' my='1em' bg="gray.200" size='sm' as={Button} leftIcon={<TimeIcon m='0' p='0' color={theme.primary} />} >
          {status}
            </Box> : null
          }
          <Flex  >
            {
              price && price.length > 0 ? <Text alignSelf='center' color='pink.500' fontSize={['lg', 'lg', 'md', 'lg']} fontWeight='bold'>{price} INR</Text> : null
            }
            <Spacer />
                <Button
                  _hover={{
                    backgroundColor: theme.primary,
                    color: 'white'
        }}
                  my='1em'
                  justifySelf='flex-start'
        as={Link} to={{ pathname: `/courses`, state: id }} 
                  size='sm' variant='outline' fontWeight='100' rightIcon={<ArrowRightIcon fontWeight='100' />} >Read more
                </Button>
        </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

