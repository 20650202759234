import React from 'react';
import MainContainer from '../components/MainContainer';
import getData, { keys } from '../data';
import {Calendar, momentLocalizer} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import _ from 'lodash';
import moment from 'moment';

const localizer = momentLocalizer(moment)

const SchoolCalendar = props => {
    return (
    <MainContainer heading="Calender">
      <Calender events={getData(props.data.school, keys.CALENDAR)} />
    </MainContainer>
  );
};

const Calender = props => (
  <Calendar
    superhero
    localizer={localizer}
    startAccessor="start"
    endAccessor="end"
    style={{ height: 500 }}
    views={['month', 'day', 'week']}
    popup
    popupOffset={{ x: 30, y: 20 }}
    events={_.map(props.events, e =>
      Object.assign(e, { start: new Date(e.startDate), end: new Date(e.endDate), allDay: true }),
    )}
    // onEventClick={(target, eventData, day) => console.log(eventData)}
  />
);

export default SchoolCalendar