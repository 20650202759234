import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
import _ from 'lodash'

const SchoolUniform = props => {
  return (
    <MainContainer heading="School Uniform">
        <Box>
            <Text fontSize='xl' fontWeight='bold'>For Boys</Text>
            <UnorderedList mt={2}>
              <ListItem>White Shorts</ListItem>
              <ListItem>Green and white striped tshirt</ListItem>
            </UnorderedList>
        </Box>
        <br/>
        <Box>
            <Text fontSize='xl' fontWeight='bold'>For Girls</Text>
            <UnorderedList mt={2}>
              <ListItem>White Shorts</ListItem>
              <ListItem>Green and white striped tshirt</ListItem>
            </UnorderedList>
        </Box>
    </MainContainer>
  );
};

export default SchoolUniform