import React from "react";
import Carousel from "react-elastic-carousel";
import getData, { keys } from "../../data";

import { getFacilities } from "../../data/Facilities";
import { Link as RLink } from 'react-router-dom';

import {
  Flex,
  Box,
  Link,
  Text,
  Avatar,
  chakra,
  useColorModeValue,
  useTheme
} from "@chakra-ui/react";
import "./code.css";

const FacilitiesCarousel = (props) => {
  const theme = useTheme()
  const facilities = getFacilities(
    getData(props.props.data.school, keys.FACILITIES)
  );
  const facilitiesImages = getData(
    props.props.data.school,
    keys.FACILITIES_IMAGES
  );

  let slides2 = [];
  for (let i = 0, j = 0; i < facilities.length; i++, j++) {
    let obj = {
      id: facilities[i].id,
      title: facilities[i].title,
      content: facilities[i].content,
      img: facilitiesImages[i]?facilitiesImages[i].image
        ? facilitiesImages[i].image
        : facilities[i].image:facilities[i].image
    };
    slides2.push(obj);
  }

  const breakPoints = [
    { width: 200, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  return (
    <>
      <Box bg="#fafafa" w={["90%", "80%", "90%", "80%"]} mx="auto" my="10px" py={["20px", "20px", "20px", "40px"]} mt="40px">
        <Text
          fontSize={["3xl", "4xl", "4xl", "4xl"]}
          fontWeight="bolder"
          textAlign="center"
        >
          Facilities
        </Text>
        <Text
          fontSize={["1xl", "xxl", "xl", "1xl"]}
          textAlign="center"
          px={["20px", "120px", "10px", "180px","180px","250px"]}
        >
          Our school provides students with facilities that are in par with international schools and designed to meet their requirements.
        </Text>

        <Box my={["0.5em", "1em"]} mx={["1em", "5em"]}>

          <Carousel breakPoints={breakPoints}>
            {slides2.map((slide,idx) => (
              <Flex
                key={idx}
                maxW={["sm", "md", "md", "full"]}
                flex-wrap="wrap"
                alignItems="stretch"
                justify="center"
                rounded="lg"
                direction="column"
                m={["5px", "5px", "5px", "10px"]}
                h={["250px", "250px", "300px", "400px"]}

              >
                <Avatar
                  mt="8px"
                  ml={["0.7em", "0.7rem"]}
                  borderRadius="full"
                  border={`solid ${theme.primary}`}
                  borderWidth={["1px", "5px"]}
                  boxSize={["80px", "120px", "120px"]}
                  src={`${slide.img}`}
                />

                <Box w="full" p={{ base: 4, md: 4 }}>
                  <chakra.h1
                    fontSize={["0.8em", "1xl", "20px","xl","xl", "2xl"]}
                    fontWeight="bolder"
                    color={useColorModeValue("gray.800", "white")}
                  >
                    {slide.title}
                  </chakra.h1>
                  <Box h="5px" w={1 / 3} bg={theme.primary}>
                    &nbsp;
                  </Box>
                  <chakra.p
                    mt={2}
                    noOfLines={[3, 4]}
                    fontSize={["0.6em", "md"]}
                    color={useColorModeValue("gray.600", "gray.400")}
                  >
                    {slide.content}
                  </chakra.p>
                  <Link
                    as={RLink}
                    to={`/facilities#section-${slide.id}`}
                    color={theme.secondary}
                    textDecoration="underline"
                    fontSize={["0.6em", "md"]}
                  >
                    Read more
                  </Link>
                </Box>
              </Flex>
            ))}
          </Carousel>
        </Box>
      </Box>
    </>
  );
};
export default FacilitiesCarousel;
