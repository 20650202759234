import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';

const KheloIndia = props => {
  const theme = useTheme();
  return (
    <MainContainer heading="Fit India">
      <Flex flexWrap="wrap">
        <Box padding="50px" w={{ base: '100%', sm: '100%', lg: '25%' }}>
          <img
            src="/images/khelo_india_logo.jpg"
            alt="Khelo India Logo"
            style={{ width: '100%', height: '200px', textAlign: 'center' }}
          />
        </Box>
        <Box w={{ base: '100%', sm: '100%', lg: '75%' }}>
          The Honorable Prime Minister launched nation-wide{' '}
          <i>“Fit India Movement”</i> aimed to encourage people to inculcate
          physical activity and sports in their everyday lives and daily
          routine. So as to take this mission forward,{' '}
          <b>
            CBSE has decided that each year SECOND and THIRD WEEK in November, a
            total of 06 working days, will be celebrated as “Fitness Week".
          </b>
          <br />
          <br />
          This movement therefore endeavors to alter this behavior from ‘Passive
          Screen time’ to ‘Active Field time’ and the aim of the objective is to
          develop Sports Quotient among all the students to achieve a healthy
          lifestyle. Such movement will also instill in students the
          understanding for regular physical activity and higher levels of
          fitness enhancing in them self-esteem and confidence.
        </Box>
      </Flex>
      <Box p={4} bg="rgb(244, 246, 246)" w="100%">
        <Text fontWeight="bold" fontSize="3xl" color={theme.secondary}>
          Activities During Fitness Week
        </Text>
        <br />
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Day</th>
              <th>Activity</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{whiteSpace: 'nowrap'}}>Day 1</td>
              <td style={{ paddingLeft: '50px' }}>
                <ol type="i">
                  <li>Morning Assembly – Yoga for all</li>
                  <li>
                    Activities on Fitness and Nutrition for Students and Staff
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td style={{whiteSpace: 'nowrap'}}>Day 2</td>
              <td style={{ paddingLeft: '50px' }}>
                <ol type="i">
                  <li>Morning Assembly – Free hands exercise for all</li>
                  <li>
                    Mental Fitness Activities (Ex. Debates, Symposium, Lectures
                    by Sports Psychologists)
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td style={{whiteSpace: 'nowrap'}}>Day 3</td>
              <td style={{ paddingLeft: '50px' }}>
                <ol type="i">
                  <li>
                    Beginning of “Fitness Assessment” of Students through KHELO
                    INDIA App.
                  </li>
                  <li>
                    Poster making Competition for all Students on theme “Fit
                    Body – Fit Mind – Fit Environment”
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td style={{whiteSpace: 'nowrap'}}>Day 4</td>
              <td style={{ paddingLeft: '50px' }}>
                <ol type="i">
                  <li>
                    Physical Activities for all Students including Dance,
                    Aerobics, Yoga, Martial Arts, Rope-Skipping, Gardening etc
                  </li>
                  <li>
                    Essay/Poem Writing Competition for all Students on theme
                    “Fit India School”
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td style={{whiteSpace: 'nowrap'}}>Day 5</td>
              <td style={{ paddingLeft: '50px' }}>
                <ul style={{ listStyle: 'none' }}>
                  <li>Fitness/Sports Quiz for all Students</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{whiteSpace: 'nowrap'}}>Day 6</td>
              <td style={{ paddingLeft: '50px' }}>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    Competition for Students, Staff and Parents in
                    Traditional/Indigenous/Regional Games – <br />
                    <b>To celebrate</b> the Unity in Diversity of our Nation and
                    to maintain and strengthen the fabric of traditionally
                    existing emotional bonds between the people of our Country;{' '}
                    <br />
                    Engagement Matrix between States and UTs have been done.
                    Against each pair of State/UTs, the suggestive traditional
                    games list too has been given. <br />
                    Schools may engage its students in minimum one game of its
                    choice from the partner state (Refer annexure “A”).
                    <br />
                    Children, Parent and Teacher participants may also
                    participate in exploring the history and interesting facts
                    of the indigenous games from partner States
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </MainContainer>
  );
};

export default KheloIndia;
