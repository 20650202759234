import {
  Box,
  Button,
  Flex,
  Input,
  Link,
  Text,
  Textarea,
  Tooltip
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import {
  FaFacebook,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
  FaWhatsapp,
  FaInstagram
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import STATUS from '../Constants';
import getData, { keys } from '../data';
import { getContactRequestAction } from '../redux/actions';
import Map from './Map';
import $ from 'jquery'

const Footer = (props) => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState('youtube');
  
  const { requestStatus } = useSelector(state => ({
    requestStatus: state.requestStatus
  }));

  useEffect(() => {
    if (requestStatus == STATUS.SUCCESS) $('#form')[0].reset();
  }, [requestStatus]);

  const submitContact = e => {
    e.preventDefault();
    dispatch(getContactRequestAction(new FormData(e.target)));
  };

  return (
    <Box color="white">
      {props.hideContact ? 
        null
        :
        <Box display={{ lg: 'flex' }} px={{base:0, md:20}} bg="#252B33">
          <Box px={{base:2, md:10}} w="100%" py={10}>
            <Text fontSize="xl" mx={2}>
              Contact Form
            </Text>
            <form id='form' onSubmit={submitContact}>
              <Flex my={2}>
                <CustomInput placeholder="Name *" name="name" />
                <CustomInput placeholder="Father Name *" name="father_name" />
                <CustomInput defaultValue={getData(props.data.school, keys.ID)} name='school_id' type='hidden' />
              </Flex>
              <Flex my={2}>
                <CustomInput placeholder="Email *" name="email" />
                <CustomInput placeholder="Contact *" type='number' name="phone" />
              </Flex>
              <Flex my={2}>
                <Textarea
                  rows={3}
                  m={2}
                  required
                  borderColor="#FDFDFD1C"
                  bg="#3B424B21"
                  borderRadius="0"
                  placeholder="Message"
                  name='message'
                ></Textarea>
              </Flex>
              <Button m={2} type='submit' color="#252B33" w="150px">
                SUBMIT
              </Button>
            </form>
          </Box>
          <Box pl={{base:6, md:10}} w="100%" py={10}>
            <Text mx={2} mb={2} fontSize="xl">
              Watch Us!
            </Text>
            <Flex>
              <Box w="90%">
                {currentTab == 'youtube' ? (
                  <Youtube
                    src={getData(props.data.school, keys.YOUTUBE)}
                  />
                ) : currentTab == 'map' ? (
                  <Box w='100%' h='500px'>
                    <Map />
                  </Box>
                ) : null}
              </Box>
              {/* <Box w="10%">
                <SideMenu selectedTab={e => setCurrentTab(e)} />
              </Box> */}
            </Flex>
          </Box>
        </Box>
      }
      <Box display={{ lg: 'flex' }} px={{base:6, md:32}} bg="#22272e" py={6} w="100%">
        <Box flexGrow={1}>
          <Text fontSize="xl">REACH US:</Text>
          <Text>{getData(props.data.school, keys.NAME)}</Text>
          <Flex mt={2}>
            {getData(props.data.school, keys.FACEBOOK_LINK) ? 
              <Box pr={4}>
                <Link target='_blank' href={getData(props.data.school, keys.FACEBOOK_LINK)}>
                  <FaFacebook className='facebook-button'/>
                </Link>
              </Box>
              : null
            }
            {getData(props.data.school, keys.YOUTUBE_LINK) ?
              <Box pr={4}>
                <Link target='_blank' href={getData(props.data.school, keys.YOUTUBE_LINK)}>
                  <FaYoutube className='youtube-button'/>
                </Link>
              </Box>
              : null
            }
            {getData(props.data.school, keys.TWITTER_LINK) ?
              <Box pr={4}>
                <Link target='_blank' href={getData(props.data.school, keys.TWITTER_LINK)}>
                  <FaTwitter className='twitter-button'/>
                </Link>
              </Box>
              : null
            }
            {getData(props.data.school, keys.WHATSAPP_CONTACT) ?
              <Box pr={4}>
                <Link target='_blank' href={`https://wa.me/91${getData(props.data.school, keys.WHATSAPP_CONTACT)}`}>
                  <FaWhatsapp className='twitter-button'/>
                </Link>
              </Box>
              : null
            }
            {getData(props.data.school, keys.INSTAGRAM_LINK) ?
              <Box pr={4}>
                <Link target='_blank' href={getData(props.data.school, keys.INSTAGRAM_LINK)}>
                  <FaInstagram className='twitter-button'/>
                </Link>
              </Box>
              : null
            }
          </Flex>
        </Box>
        <Box p={2} flexGrow={1}>
          <Flex
            p={3}
            borderRadius="21px"
            border="1px solid #FDFDFD1C"
          >
            <Box padding='1'>
              <FaMapMarkerAlt fontSize={14} />
            </Box>
            <Box> 
              {getData(props.data.school, keys.ADDRESS)},{' '}
              {getData(props.data.school, keys.CITY)}, {' '}
              {getData(props.data.school, keys.STATE)} -{' '}
              {getData(props.data.school, keys.PINCODE)}
            </Box>
          </Flex>
        </Box> 
        <Box p={2} flexGrow={1}>
          {!getData(props.data.school, keys.EMAIL) ?  null :
            <Flex p={3} borderRadius="21px"
              // h="42px"
              border="1px solid #FDFDFD1C"
            >
              <Box padding='1'>
                <BsFillEnvelopeFill fontSize={14} />
              </Box>

              <Box>               
                <Link
                  _hover={{
                    color: "#4299e1",
                  }}
                  isExternal="true" href={`mailto:${getData(props.data.school, keys.EMAIL)}`}>
                  {getData(props.data.school, keys.EMAIL)}
                </Link>
              </Box>
            </Flex>
          }
        </Box>
        <Box p={2} flexGrow={1}>
            {!getData(props.data.school, keys.PRIMARY_CONTACT) && !getData(props.data.school, keys.SECONDARY_CONTACT) ? null : 
              <Flex
                p={3}
                borderRadius="21px"
                // h="42px"
                border="1px solid #FDFDFD1C"
              >
                <Box padding='1'>
                  <FaPhoneAlt fontSize={14} />
                </Box>
                <Box>
                  {_.join(_.compact([getData(props.data.school, keys.PRIMARY_CONTACT), getData(props.data.school, keys.SECONDARY_CONTACT)]), '/')}
                </Box>
              </Flex>
            }
        </Box>
      </Box>
      <Box textAlign="center" p={2} bg="#0F1216">
        <Text fontSize="sm">
          © Copyright 2015-Present - All Rights Reserved
        </Text>
      </Box>
    </Box>
  );
};

const SideMenu = ({ selectedTab }) => {
  const [currentTab, setCurrentTab] = useState('youtube');
  const changeTab = tab => {
    setCurrentTab(tab);
    selectedTab(tab);
  };

  return (
    <Flex flexDir='column'>
      <Box>
        <Tooltip hasArrow label="Video" aria-label="A tooltip" bg='white' color='black' placement='right'>
          <Box
            p={2}
            mx={2}
            bg={currentTab == 'youtube' && 'white'}
            color={currentTab == 'youtube' ? '#252B33' : '#E5E8E8'}
            onClick={() => changeTab('youtube')}
            transition="background 0.4s, color 0.4s"
            fontSize={30}
            _hover={{ color: '#252B33', background: 'white' }}
            cursor="pointer"
          >
            <FaYoutube style={{marginRight:'auto', marginLeft:'auto', display:'block'}}/>
          </Box>
        </Tooltip>

      </Box>
      {/* <Box>
        <Tooltip hasArrow label="Map" aria-label="A tooltip" bg='white' color='black' placement='right'>
          <Box
            p={2}
            m={2}
            bg={currentTab == 'map' && 'white'}
            color={currentTab == 'map' ? '#252B33' : '#E5E8E8'}
            onClick={() => changeTab('map')}
            fontSize={30}
            transition="background 0.4s, color 0.4s"
            _hover={{ color: '#252B33', background: 'white' }}
            cursor="pointer"
          >
            <FaMapMarkedAlt style={{marginRight:'auto', marginLeft:'auto', display:'block'}}/>
          </Box>
        </Tooltip>
      </Box> */}
    </Flex>
  );
};

const Youtube = props => (
  <iframe
    width="100%"
    height="300vh"
    src={props.src}
    frameBorder="0"
    allowFullScreen
  />
);

const CustomInput = ({ placeholder, name, type, defaultValue }) => {
  return (
    <Input
      m={2}
      required
      name={name}
      type={type || 'text'}
      borderColor="#FDFDFD1C"
      bg="#3B424B21"
      borderRadius="0"
      defaultValue={defaultValue}
      placeholder={placeholder}
    />
  );
};

export default Footer;
