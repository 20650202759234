import {
  Box,
  Flex,
  List,
  ListItem,
  Button,
  Text,
  useTheme
} from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
import getData, { convertHTMLString, keys } from '../data';
import _ from 'lodash';
import { AiOutlineDownload } from 'react-icons/ai';
import { Link } from 'react-router-dom';
const Downloads = props => {
  const theme = useTheme();
  const downloadFile = (fac) => { };
  return (
    <MainContainer heading="Download Files">
      <Flex flexWrap="wrap" p={2} justify="center">
        {getData(props.data.school, keys.DOCUMENTS)
          .filter((s) => !s.type || s.type != "cbse")
          .map((doc) => (
            <Box
              border="1px solid rgba(0,0,0,.125)"
              w={{ base: "100%", sm: "100%", lg: "25%" }}
              p={4}
              m="10px"
              key={doc.id}
            >
            <Text fontSize="xl" color={theme.secondary} textAlign="center">
              {_.upperCase(doc.name)}
            </Text>
              <Text fontSize="15px" color={theme.secondary} textAlign="center">
                {_.lowerCase(doc.description)}
              </Text>
            <List>
              {doc.files.map((file, i) => (
                <ListItem
                  key={i}
                  onClick={() => downloadFile(file)}
                    borderTopLeftRadius={i == 0 ? ".25rem" : ""}
                    borderTopRightRadius={i == 0 ? ".25rem" : ""}
                  borderBottomLeftRadius={
                      doc.files.length == i + 1 ? ".25rem" : ""
                  }
                  borderBottomRightRadius={
                      doc.files.length == i + 1 ? ".25rem" : ""
                  }
                  fontWeight="bold"
                >
                    {doc.files.length > 1 ? (
                      <Button
                        m="5px"
                        as={Link}
                        target="_blank"
                        onClick={() => window.open(file.file)}
                        colorScheme="blue"
                      >
                        
                        Download File {i + 1}
                      </Button>
                    ) : (
                      <Button
                        m="5px"
                        as={Link}
                        target="_blank"
                        onClick={() => window.open(file.file)}
                        colorScheme="blue"
                    >
                        Download
                      </Button>
                    )}
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Flex>
    </MainContainer>
  );
};

export default Downloads;
