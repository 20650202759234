// import { Box } from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
// import getData, { convertHTMLString, keys } from '../data';

const Team = props => {
  return (
    <MainContainer heading="Team">
      
    </MainContainer>
  );
};

export default Team