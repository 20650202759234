import React, { useEffect } from 'react';
import HomeTop from '../components/HomeTop';
import HomeIntro from '../components/HomeIntro';
import { Box } from '@chakra-ui/react';
import NewsAndEvents from '../components/NewsAndEvents';
import HomeFacilities from '../components/HomeFacilities';
import HomePhotoes from '../components/HomePhotoes';
import TeamCarousal from '../components/TeamCarousal';
import TestimonialCarousal from '../components/TestimonialCarousal';

const Home = props => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box color="#252B33" minHeight="400px">
      <HomeTop {...props}/>
      <HomeIntro {...props} />
      <NewsAndEvents {...props}/>
      <HomeFacilities {...props}/>
      <HomePhotoes {...props}/>
      {/* <TeamCarousal {...props}/>
      <TestimonialCarousal {...props}/> */}
    </Box>
  );
};

export default Home;
