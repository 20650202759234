import { Box, Flex, Heading, Text, useTheme, Image, Stack, Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import $ from 'jquery';
import getData, { keys } from '../data';
import _ from 'lodash'
import { useHistory } from 'react-router-dom';
import Path, { NewLink } from '../data/Path';
import { IoIosImage } from 'react-icons/io';

const HomePhotoes = props => {
  let theme = useTheme();
  let history = useHistory()
  
  useEffect(() => {
    function moveToSelected(element) {
      if (element == 'next') {
        var selected = $('.selected').next();
      } else if (element == 'prev') {
        var selected = $('.selected').prev();
      } else {
        var selected = element;
      }

      var next = $(selected).next();
      var prev = $(selected).prev();
      var prevSecond = $(prev).prev();
      var nextSecond = $(next).next();

      $(selected)
        .removeClass()
        .addClass('selected');

      $(prev)
        .removeClass()
        .addClass('prev');
      $(next)
        .removeClass()
        .addClass('next');

      $(nextSecond)
        .removeClass()
        .addClass('nextRightSecond');
      $(prevSecond)
        .removeClass()
        .addClass('prevLeftSecond');

      $(nextSecond)
        .nextAll()
        .removeClass()
        .addClass('hideRight');
      $(prevSecond)
        .prevAll()
        .removeClass()
        .addClass('hideLeft');
    }

    // Eventos teclado
    $(document).keydown(function(e) {
      switch (e.which) {
        case 37: // left
          moveToSelected('prev');
          break;

        case 39: // right
          moveToSelected('next');
          break;

        default:
          return;
      }
      e.preventDefault();
    });

    $('#carousel div').click(function() {
      moveToSelected($(this));
    });

    $('#prev').click(function() {
      moveToSelected('prev');
    });

    $('#next').click(function() {
      moveToSelected('next');
    });
  }, []);

  return (
    <Box textAlign="center" bg="transparent linear-gradient(174deg, #F6F7F9 0%, #F6F7F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box" pt={16} >
      <Text color={theme.secondary}>Discover Our Images</Text>
      <Heading color={theme.primary} size="xl">
        Our Gallery
      </Heading>
      <br/><br/>
      <Stack w='100%' spacing={1} direction={["column", "row"]}>
          {_.chain( getData(props.data.school, keys.GALLERY))
            .filter(val => val.images && val.images.length)
            .flatMap(val => val.images)
            .filter((val, i) => i <= 3)
            .map((img, i) => 
              <Box key={i} onClick={() => NewLink(history, Path.GALLERY)} className='imgContainer' w={{base:'100%', md:'33.33%'}}>
                <Image className='galleryImg' src={img.image} h={{base:'300px', md:'400px'}} objectFit='cover'/>
                <Flex alignItems='center' className='middle'>
                  <Flex flexDirection='column' alignItems='center' color='white'>
                    <IoIosImage fontSize={50}/>
                    GALLERY
                  </Flex>
                </Flex>
              </Box>
            )
            .value()
          }
      </Stack>
      <Stack p={10} justifyContent='space-around' direction={['column', 'row']} spacing='24' bg={theme.primary}>
          <Box color='white' textAlign='left'>
            <Text fontWeight='500' fontSize='3xl'>DISCOVER MORE IMAGES?</Text>
            <Text fontSize='xl'>School just got a lot more convenient</Text>
          </Box>
          <Box>
            <Button onClick={() => NewLink(history, Path.GALLERY)} size='lg' bg='white' variant="outline" color={theme.primary} fontWeight='bold'>TO GALLERY NOW!</Button>
          </Box>
      </Stack>
      {/* <main style={{ marginTop: '250px' }}>
        <div id="carousel">
          {_.chain( getData(props.data.school, keys.GALLERY))
            .filter(val => val.images && val.images.length)
            .flatMap(val => val.images)
            .map((d, i) => 
              <ImageSlide
                status={
                  i == 0 ?  "hideLeft" :
                  i == 1 ?  "prevLeftSecond" : 
                  i == 2 ?  "prev" :
                  i == 3 ?  "selected" :
                  i == 4 ?  "next" :
                  i == 5 ?  "nextRightSecond" :
                  i == 6 ?  "hideRight" 
                  : "hideRight"
                }
                schoolImg={d.image}
              />
            )
            .value()
          }
        </div>
      </main> */}
    </Box>
  );
};

const ImageSlide = ({ schoolImg, status }) => {
  return (
    <div className={status}>
      <img src={schoolImg} />
    </div>
  );
};

export default HomePhotoes;
