import { Box, Flex, Heading, HStack, Text, Spacer, Image } from '@chakra-ui/react'
import React from 'react'
import moment from 'moment'

const getSessionName = (session) => {
	return _.chain(session).split('_').map(d => '20' + d).join('-').value()
}

const AdmissionFormPrint = (props) => {
	let data = props.data.enquiry
	let school = props.data.school
	const enquirySession = props.data.school.enquirySettings?.data?.enquirySession

	return (
		<Box>
			<Box>
				<SchoolHeader school={school} showLogo showAddress />
				<div style={{ textAlign: 'center', padding: '0px', margin: '0px' }}>
					<b>Session: {getSessionName(enquirySession ? enquirySession : '24_25')}</b>
				</div>
				<Text style={{ fontSize: '22px', textAlign: 'center' }} fontWeight='bold'><u>ADMISSION FORM</u></Text>
			</Box>
			<br />
			<Box>
				<Flex>
					<Box minWidth='200px'>
						<span style={{ paddingRight: '20px', fontWeight: 'bold' }}>Token No</span>
						<span style={{ borderBottom: '1px dashed black', paddingTop: '19px', paddingLeft: '10px', paddingRight: '50px' }}>
							{data.token}
						</span>
					</Box>
					<Spacer />
					<Box minWidth='200px'>
						<span style={{ paddingRight: '20px', fontWeight: 'bold' }}>Date</span>
						<span style={{ borderBottom: '1px dashed black', paddingTop: '19px', paddingLeft: '10px', paddingRight: data.token ? '50px' : '120px' }}>
							{data.token ? moment().format('DD-MM-YYYY') : '    '}
						</span>
					</Box>
				</Flex>
				<br />
				<Box fontWeight='bold' mt='12px' px='5px' mb='15px' fontSize='18px' borderBottom='1px solid' borderColor='#CBD5E0' backgroundColor='#EDF2F7'>
					Student Details
				</Box>
				<Box px='10px'>

					<Input label="Student Name" value={data.name} />
					<Input label="Email" value={data.email} />
					<Input label="Gender" value={data.gender} />
					<Input label="Date Of Birth" value={data.dob && data.dob.date ? moment(data.dob.date).format('DD-MM-YYYY') : null} />
					<Input label="Aadhar Number" value={data.adhar_no || null} />
					<Input label="Address" value={data.address || null} />
					<Input label="City" value={data.city || null} />
					<Input label="State" value={data.state ? data.state.data.name : null} />
					<Input label="Pin Code" value={data.pin || null} />
					<Input label="Religion" value={data.religion || null} />
					<Input label="Category" value={data.category || null} />
					<Input label="Nationality" value={data.nationality || null} />
					<Input label="Class" value={data.standard && data.standard.data.name || null} />
					<Input label="Medium" value={data.medium || null} />
				</Box>
				<Box px='5px' fontWeight='bold' mt='12px' mb='15px' fontSize='18px' borderBottom='1px solid' borderColor='#CBD5E0' backgroundColor='#EDF2F7'>
					Parent/Guardian Details
				</Box>
				<Box px='10px'>
					<Input label="Father's Name" value={data.father_name || null} />
					<Input label="Father's Contact" value={data.father_contact || null} />
					<Input label="Father's Occupation" value={data.father_occupation || null} />
					<Input label="Mother's Name" value={data.mother_name} />
					<Input label="Mother's Contact" value={data.mother_contact} />
					<Input label="Mother's Occupation" value={data.mother_occupation} />
				</Box>

				<Box px='5px' fontWeight='bold' mt='12px' mb='15px' fontSize='18px' borderBottom='1px solid' borderColor='#CBD5E0' backgroundColor='#EDF2F7'>
					Previous School Details
				</Box>
				<Box px='10px'>
					<Input label="Last Class" value={data.last_class} />
					<Input label="Last School Name" value={data.last_school} />
					<Input label="Grades" value={data.last_school_percent} />
					<Input label="Result" value={data.last_school_result} />

				</Box>

			</Box>
		</Box>
	)
}

const Input = (props) => {
	return (
		<HStack fontSize='16px' my='2px'>
			<Box w='25%'>
				<Text fontWeight='bold' >{props.label}</Text>
			</Box>
			<Box w='75%' style={{ borderBottom: '1px dashed black', paddingTop: props.value ? '' : '19px', paddingLeft: '0px' }}>
				<Text pl='5px'>{props.value}</Text>
			</Box>
		</HStack>
	)
}


export const SchoolHeader = ({ school, showLogo, showAddress }) => {
	return (
		<div style={{ minHeight: '70px' }}>
			{showLogo && school.logo.image ?
				<Image
					style={{
						height: '80px',
						marginLeft: '-20px',
						maxWidth: '100px !important',
						position: 'absolute',
						top: '5px',
						left: '20px'
					}}
					src={school.logo.image}
				/>
				:
				null
			}
			<h4 style={{ textAlign: 'center', textTransform: 'uppercase', paddingTop: '5px', marginBottom: '10px', fontSize: '24px', paddingLeft: '50px', fontWeight: 'bold' }} >{school.name}</h4>

			<div style={{ paddingLeft: '50px' }}>
				{showAddress ?
					<h6 style={{ textAlign: 'center', textTransform: 'uppercase', whiteSpace: 'pre-wrap !important', margin: '0px', fontSize: '11px', fontWeight: 'normal' }}>{getSchoolAddressLine(school)}</h6>
					:
					null
				}
			</div>
		</div>
	)


}

export const getSchoolAddressLine = (school) => {
	let lineOne = _.compact([school.address, school.city]).join(', ')
	let lineTwo = (school.contact1 || school.contact2) ? ' Helpline: ' + _.compact([school.contact1, school.contact2]).join(',') : ''
	if (!lineTwo)
		return lineOne;
	if (lineOne.length < 60)
		return _.compact([lineOne, lineTwo]).join(',')
	else
		return (lineOne + '\n' + lineTwo)

}

export default AdmissionFormPrint