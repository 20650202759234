import App from "./App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { hydrate, render } from "react-dom";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import MainReducer from "./redux/reducers/MainReducer";
import { ChakraProvider } from "@chakra-ui/react";

import customTheme, { newTheme } from "./theme";

const serverData = window.__SERVER_DATA__;
const store = createStore(MainReducer, serverData, applyMiddleware(thunk));

const root = document.getElementById("root")
var renderMethod
if (root && root.innerHTML !== "") {
  renderMethod = hydrate
} else {
  renderMethod = render
}
let Theme = newTheme ? newTheme : customTheme ;
setTimeout(() => {
  if (newTheme) {
    Theme = newTheme;
    renderMethod(
      <Provider store={store}>
        <BrowserRouter>
          <ChakraProvider theme={Theme}>
            <App />
          </ChakraProvider>
        </BrowserRouter>
      </Provider>,
    
      document.getElementById("root")
    );
  }
}, 1000);

renderMethod(
  <Provider store={store}>
    <BrowserRouter>
      <ChakraProvider theme={Theme}>
      <App />
      </ChakraProvider>
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
