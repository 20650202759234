import { Box, Flex, Heading, useTheme } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { animate } from 'framer-motion'
import {default as MobileMenu, SubMenu, Item} from 'rc-menu';
import _ from 'lodash'
import { useHistory } from 'react-router-dom';


const CustomMobileHeader = (props) => {
    const history = useHistory()
    const theme = useTheme()
    
    const closeNavBar = () => {
        document.getElementById("mySidenav").style.width = "0";
    }

    return(
        <Box id='mySidenav' className='sidenav'>
            <Box>
                <Flex justifyContent='flex-end' w='100%' pr={10} pb={5}>
                    {/* <Box>
                        <Heading size='md' color={theme.peimary}>{props.data.school.name}</Heading>
                    </Box> */}
                    <Box>
                        <AiOutlineClose fontSize='20' onClick={props.closeSideNav} cursor='pointer'/>
                    </Box>
                </Flex>
                <Box px={4}>
                    <MenuData {...props} history={history} closeNavBar={closeNavBar} />
                </Box>
            </Box>
        </Box>
    )
}


const MenuData = props =>
  React.cloneElement(InlineMenu(props), {
    mode: 'inline',
    openAnimation: animation,
    onSelect: (info) => {
        props.closeNavBar()
        props.history.push(info.key);
    },
});

const animation = {
  enter(node, done) {
    let height;
    return animate(node, 'rc-menu-collapse', {
      start() {
        height = node.offsetHeight;
        node.style.height = 0;
      },
      active() {
        node.style.height = `${height}px`;
      },
      end() {
        node.style.height = '';
        done();
      },
    });
  },
};

const InlineMenu = (props) => {
    return(
      <MobileMenu selectedKeys={[]} style={{ justifyContent: 'right' }}>
        <Item disabled className="mr-auto" style={{ padding: '1px', marginLeft: '60px' }}>
          {/* {scrollTop > 132 ? (
            <div style={{ height: '50px', width: '100px' }}>
              <img
                src={getData(props.data.school, keys.LOGO)}
                style={{ maxWidth: '100%', height: '100%' }}
              />
            </div>
          ) : null} */}
        </Item>
        {_.map(props.data.menu,
          m =>
            (_.size(m.children) == 1 ? (
              <Item key={m.children[0].path} style={{ cursor: 'pointer' }}>
                <b>{m.children[0].name}</b>
              </Item>
            ) : (
              <SubMenu
                style={{ cursor: 'pointer' }}
                title={
                  <span>
                    <b>{m.menu.name}</b>
                  </span>
                }
                key={m.menu.id}
              >
                {_.map(m.children, c => (
                  <Item style={{ cursor: 'pointer' }} key={c.path}>
                    {c.name}
                  </Item>
                ))}
              </SubMenu>
            )),
        )}
      </MobileMenu>
    )
  }

export default CustomMobileHeader