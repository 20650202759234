import React, { useEffect } from 'react';
import getData, { keys } from '../data';
import paths from '../data/Path';
import _ from 'lodash';
import MainContainer from '../components/MainContainer';
import { Box } from '@chakra-ui/react';

const Intro = props => (
  <Box display={{ lg: 'flex' }}>
    <Box w={{ base: '100%', sm: '100%', lg: '30%' }} pr={10} py={10}>
      {props.image ? (
        <img src={props.image} alt="#pic" style={{ width: '100%' }} />
      ) : null}
    </Box>
    <Box w={{ base: '100%', sm: '100%', lg: '70%' }} py={10}>
      <Box>{props.content}</Box>
      <br />- <b>{props.name}</b>
    </Box>
    {/* <div className="row p-5" style={{ fontSize: '18px' }}>
            <div className="col-md-4" style={{ padding: '20px', minHeight: '350px' }}>
            
            </div>
            <div className="col-md-8">
            <p style={{ color: 'grey', textAlign: 'justify' }}></p>
            <br />
            <h4>- {props.name}</h4>
            </div>
        </div> */}
  </Box>
);

const Desk = props => {
  const getPersonDesk = (persons, designation_name) => {
    const personByDesignationName = _.find(persons, p =>  p.designation_name ?  p.designation_name.replace(/[^a-zA-Z0-9-_]/g, '') == designation_name : false);
    const personByDesignation=  _.find(persons, p => p.designation ? p.designation.replace(/[^a-zA-Z0-9-_]/g, '') == designation_name : false);
    
    const person=personByDesignation?personByDesignation:personByDesignationName
    if (!person ) return null;

    return (
      <MainContainer heading={_.toUpper(person.designation_name?person.designation_name:person.designation)}>
        <Intro
          content={person.message || 'Default message'}
          image={person.image || '/images/img_avatar2.png'}
          name={person.name || `Mx. ${_.capitalize(person.designation_name?person.designation_name:person.designation)}`}
        />
      </MainContainer>
    );
  };
  return (
    <div>
      {getPersonDesk(getData(props.data.school, keys.PERSONS), props.match.params.person)}
    
      {/* {props.match.url == paths.DIRECTORDESK
        ? getPersonDesk(getData(props.data.school, keys.PERSONS), 'director')
        : props.match.url == paths.FOUNDERDESK
        ? getPersonDesk(getData(props.data.school, keys.PERSONS), 'founder')
        : props.match.url == paths.PRINCIPALDESK
        ? getPersonDesk(getData(props.data.school, keys.PERSONS), 'principal')
        : props.match.url == paths.SECRETARYDESK
        ? getPersonDesk(getData(props.data.school, keys.PERSONS), 'secretary')
        : props.match.url == paths.MANAGERDESK
        ? getPersonDesk(getData(props.data.school, keys.PERSONS), 'manager')
        : null} */} 
    </div>
  );
};

export default Desk;
