import { Box, Button, Heading, Text, useTheme } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import $ from 'jquery'
import _ from 'lodash'
import getData, { keys } from '../data';
import { useState } from 'react';

const HomeTop = props => {
  let theme = useTheme();
  return (
    <Box>
      <Carousal imagesList={_.map(getData(props.data.school, keys.CAROUSEL), im => im.image)}/>
      {/* <Box color="white" display={{ lg: 'flex' }} w="100%">
        <Box
          w="100%"
          p={10}
          borderRight={'6px solid ' + theme.secondary}
          bg={theme.primary}
        >
          <Heading fontSize="41px">
            OUTSTANDING RESULT IN BOARD EXAM [2019-20]
          </Heading>
          <br />
          <br />
          <Text color={theme.secondary} fontSize="43px">
            CBSE Class X -100%
          </Text>
          <Text color={theme.secondary} fontSize="43px">
            CBSE Class XII -100%
          </Text>
          <br />
          <br />
          <Button
            size="lg"
            borderRadius="29px"
            h="58px"
            w="422px"
            bg={theme.secondary}
          >
            Online Admission Form
          </Button>
        </Box>
        <Box w="100%" bg="white" overflow="hidden">
          <Box>
            <img src="/images/student-image.jpg" />
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};

const Carousal = ({imagesList}) => {
  const [carousalHeight, setCarousalHeight] = useState('')

  useEffect(() => {
    const colors = ['#808e9b', '#f1c40f', "#3498db", "#c0392b", "#cd6133"]
      const imageCollection = imagesList.map(img => 
          ({
            link:img,
            color: colors[_.random(0, 4)]  
          })
        )
    
      const settings = {
        duration: "3500",
        random: false
      };
    
      var prevButton = $(".prev"),
        images = $(".slide"),
        index = 0,
        icons = $(".buttons"),
        slideCount = imageCollection.length,
        interval,
        nextButton = $(".next");

      autoSlider(settings);

      nextButton.click(function (e) {
        index++;
        showSlide(index);
      });
    
      prevButton.click(function () {
        index--;
        showSlide(index);
      });
    
      icons.mouseover(function () {
        clearInterval(interval);
      });
    
      icons.mouseleave(function () {
        autoSlider(settings);
      });
    
      function autoSlider(settings) {
        var prev;
        interval = setInterval(function () {
          if (settings.random) {
            do {
              index = Math.floor(Math.random() * slideCount);
            } while (index == prev);
            prev = index;
          } else {
            if (slideCount == index) {
              index = 0;
            }
            index++;
          }
    
          showSlide(index);
        }, settings.duration);
      }
    
      function showSlide(i) {
        index = i;
    
        if (i < 0) {
          index = slideCount - 1;
        }
    
        if (i >= slideCount) {
          index = 0;
        }
    
        images.css("background-image", "url(" + imageCollection[index].link + ")");
        images.css("background-size", "100% 100%");
        icons.css("color", imageCollection[index].color);
      }
    
  }, [])
  
  return(
      <Box className="slider" id='slider' h={{base:'300px', md:'700px'}}>
        <Box className="slide">
          <span className="prev buttons">
            <BiChevronLeft fontSize={{base:'20', md:'70'}}/>
          </span>
          <span className=" next buttons">
            <BiChevronRight fontSize={{base:'20', md:'70'}}/>
          </span>
        </Box>
      </Box>
  )
}

export default HomeTop;
