import {
  Box,
  Flex,
  IconButton,
  List,
  ListItem,
  Button,
  Text,
  useTheme,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link
} from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
import getData, { convertHTMLString, keys } from '../data';
import _ from 'lodash';
import { AiOutlineDownload } from 'react-icons/ai';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const CbseDownloads = props => {
  const theme = useTheme();

  const downloadFile = fac => { };
  let docData = getData(props.data.school, keys.DOCUMENTS).filter((s) => s.type == "cbse")
  return (
    <MainContainer heading="CBSE Files">
      {
        docData && docData.length > 0 ?

          <Table my="2em" mx="auto" border="1px solid" borderColor="gray.300" variant="striped" w="70%"  >
            <Thead>
              <Tr >
                <Th w="5%">#</Th>
                <Th >Information</Th>
                <Th  >Details</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                docData.map((doc, idx) =>
                  <Tr key={idx}>
                    <Td>{idx + 1}</Td>
                    <Td>{doc.description && doc.description != 'null' ? doc.description : doc.name}</Td>
                    <Td >
                      {
                        doc.files.length > 1 ?
                          doc.files.map((file, index) =>
                            <div style={{ margin: '5px 0px' }}>
                              <Link isExternal style={{ 'color': theme.secondary }} key={index} href={`/file/` +  file.file.replace('https://live-sl-media-storage.s3.ap-south-1.amazonaws.com/', '')}> {(doc.name ? doc.name : 'File') + ' ' + (index + 1)} <ExternalLinkIcon/></Link>
                            </div>
                          )
                          :
                          <div style={{ margin: '5px 0px' }}>
                            <Link isExternal style={{ 'color': theme.secondary }} target="_blank" key={idx} href={`/file/` + doc.files[0].file.replace('https://live-sl-media-storage.s3.ap-south-1.amazonaws.com/', '')}>{(doc.name ? doc.name : 'File')} <ExternalLinkIcon/> </Link>
                          </div>
                      }

                    </Td>
                  </Tr>
                )
              }


            </Tbody>

          </Table>

          : <Text textAlign="center" fontSize="1em">No Files Available</Text>
      }



      {/* <Flex flexWrap="wrap" p={2} justify="center">
      {getData(props.data.school, keys.DOCUMENTS)
        .filter((s) => s.type == 'cbse')
        .map((doc) => (
          <Box
            border="1px solid rgba(0,0,0,.125)"
            w={{ base: "100%", sm: "100%", lg: "25%" }}
            p={4}
            m="10px"
            key={doc.id}
          >
          <Text fontSize="xl" color={theme.secondary} textAlign="center">
            {_.upperCase(doc.name)}
          </Text>
            <Text fontSize="15px" color={theme.secondary} textAlign="center">
              {_.lowerCase(doc.description)}
            </Text>
          <List>
            {doc.files.map((file, i) => (
              <ListItem
                key={i}
                onClick={() => downloadFile(file)}
                  borderTopLeftRadius={i == 0 ? ".25rem" : ""}
                  borderTopRightRadius={i == 0 ? ".25rem" : ""}
                borderBottomLeftRadius={
                    doc.files.length == i + 1 ? ".25rem" : ""
                }
                borderBottomRightRadius={
                    doc.files.length == i + 1 ? ".25rem" : ""
                }
                fontWeight="bold"
              >
                  {doc.files.length > 1 ? (
                    <Button
                      m="5px"
                      as={Link}
                      target="_blank"
                      href={file.file}
                      colorScheme="blue"
                    >
                      
                      Download File {i + 1}
                    </Button>
                  ) : (
                    <Button
                      m="5px"
                      as={Link}
                      target="_blank"
                      href={file.file}
                      colorScheme="blue"
                  >
                      
                      Download
                    </Button>
                  )}
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </Flex> */}
    </MainContainer>





    // <MainContainer heading="Cbse Files">
    //   <Flex flexWrap="wrap" p={2}>
    //     {getData(props.data.school, keys.DOCUMENTS).filter(s => s.type == 'cbse').map(doc => (
    //       <Box w={{ base: '100%', sm: '100%', lg: '25%' }} p={4} key={doc.id}>
    //         <Text fontSize="xl" color={theme.secondary} textAlign="center">
    //           {_.upperCase(doc.name)}
    //         </Text>
    //         <List>
    //           {doc.files.map((file, i) => (
    //             <ListItem
    //               key={i}
    //               onClick={() => downloadFile(file)}
    //               border="1px solid rgba(0,0,0,.125)"
    //               borderTopLeftRadius={i == 0 ? '.25rem' : ''}
    //               borderTopRightRadius={i == 0 ? '.25rem' : ''}
    //               borderBottomLeftRadius={
    //                 doc.files.length == i + 1 ? '.25rem' : ''
    //               }
    //               borderBottomRightRadius={
    //                 doc.files.length == i + 1 ? '.25rem' : ''
    //               }
    //               fontWeight="bold"
    //             >
    //               <a href={file.file} target="_blank">
    //                 <Flex
    //                   justifyContent="space-between"
    //                   cursor="pointer"
    //                   p=".75rem 1.25rem"
    //                 >
    //                   <Text>{`${'document'} - ${++i}`}</Text>
    //                   <AiOutlineDownload color="#3498DB" fontSize="28px" />
    //                 </Flex>
    //               </a>
    //             </ListItem>
    //           ))}
    //         </List>
    //       </Box>
    //     ))}
    //   </Flex>
    // </MainContainer>
  );
};

export default CbseDownloads;
