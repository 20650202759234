import { Box, Container, Flex, Image, Text, useTheme } from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
import getData, { keys } from '../data';
import _ from 'lodash'
const Achievements = props => {
    const theme = useTheme()
    return (
        <MainContainer heading="Achievements">
            {getData(props.data.school, keys.ACHIEVEMENTS).map(ach => 
                <Box key={ach.id}>
                    <Text fontSize='3xl' textAlign='center' color={theme.secondary}><b>{ach.name}</b></Text>
                    <br/>
                    {ach.headings.map(head => 
                        <Box key={head.id} borderRadius='5px' border="1px solid #D6DBDF" p={2}>
                            <Box padding={2} background={theme.primary} color='white'>
                                <Text fontSize='2xl' textAlign='center'>{head.heading}</Text>
                            </Box>
                            <br/>
                            {head.sub_headings.map(sub => 
                                <Box key={sub.id} px={4}>
                                    <br/><br/>
                                    <Text fontSize='3xl' textAlign='center' fontWeight='bold'>{sub.sub_heading}</Text>
                                    <br/>
                                    <Flex flexWrap='wrap' justifyContent='center'>
                                        {_.concat(sub.achievement_items).map(item => 
                                            <Box w={{base:'100%', sm:'100%', lg:'20%'}} key={item.id} pr={4} mt={8}>
                                                <PersonCard item={item}/>
                                            </Box>
                                        )}
                                    </Flex>
                                </Box>
                            )}
                            <br/>
                        </Box>
                    )}
                    <br/><br/><br/>
                </Box>
            )}
        </MainContainer>
    );
};

const PersonCard = ({item}) => {
    const theme = useTheme()
    return(
        <Container className="birthday-card">
            <Box>
                <Image src={item.image.image || '/images/img_avatar2.png'} w='100%' h='250px'/>
            </Box>
            <Box p={2} className="birthday-card-container" textAlign='center'>
                <Text fontWeight='bold' color={theme.primary}>{item.body.detail1}</Text>
                {item.body.detail3 == 'null' ? null : <Text fontSize='md'> {item.body.detail3}</Text>}
                <Text fontSize='md'>{item.body.detail2}</Text>
                
            </Box>
        </Container>
    )
}

export default Achievements