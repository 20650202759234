import { Box } from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
import getData, { convertHTMLString, keys } from '../data';

const EligibilityCriteria = props => {
  return (
    <MainContainer heading="Eligibility Criteria">
      <Box
        dangerouslySetInnerHTML={convertHTMLString(
          getData(props.data.school, keys.ELIGIBILITY_CRITERIA)
        )}
      />
    </MainContainer>
  );
};

export default EligibilityCriteria;
