import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { HiOutlineEmojiSad } from "react-icons/hi"

const NoMatch = () => {
    return(
        <Flex flexDir='column' justifyContent='center' alignItems='center'  p={20}>
            <HiOutlineEmojiSad fontSize='200px' color='#444444'/>
            <Text fontSize='60px' color='#444444' fontWeight='100'>404</Text>
            <Text color='#9A9899' fontSize='30px'>page not found</Text>
            <Text>The Page you are looking for doesn't exist.</Text>
        </Flex>
    )
}

export default NoMatch