import { Box, Button, Flex, Image, Spacer, Text } from '@chakra-ui/react';
import React from 'react'
import { AiOutlineMenuFold } from 'react-icons/ai';
import 'rc-menu/assets/index.css';
import getData, { keys } from '../data';
import _ from 'lodash'
import CustomMobileHeader from './CustomMobileHeader';
import { NewLink } from '../data/Path';
import { useHistory } from 'react-router';
import paths from "../data/Path";
import { SchoolName5 } from './Header';
const MobileHeader = (props) => {
    let history = useHistory();
    const openSideNav = () => {
        document.getElementById("mySidenav").style.width = "100%";
    }

    const closeSideNav = () => {
        document.getElementById("mySidenav").style.width = "0";
    }

    return (
        <Box className='mobileHeader' position='sticky' zIndex='10' top={0} background='#F4F6F7' name="viewport">
            <Flex py={4} px={6} alignItems='center'>
                <Box>
                    <Image src={getData(props.data.school, keys.LOGO)} onClick={() => NewLink(history, paths.HOME)} w='2em' />
                </Box>
                {props.templateDesign == '5' ?
                    null
                    :
                    <Box px={4}>
                        <Text fontSize='sm'
                            cursor="pointer"
                            onClick={() => NewLink(history, paths.HOME)}
                        >
                            {getData(props.data.school, keys.NAME)}
                        </Text>
                    </Box>
                }
                <Spacer />
                <Box onClick={openSideNav}>
                    <AiOutlineMenuFold fontSize='30px' />
                </Box>
            </Flex>
            <CustomMobileHeader {...props} closeSideNav={closeSideNav} />
        </Box>
    )
}

export default MobileHeader