import { Box } from '@chakra-ui/react';
import React from 'react'
import getData, { convertHTMLString, keys } from '../data';
import MainContainer from '../components/MainContainer';
const MandatoryDisclosure = (data) => {
    return (
        <MainContainer heading="Mandatory Public Disclosure">

            <Box dangerouslySetInnerHTML={convertHTMLString(
                getData(data.data.school, keys.MANDATORY_DISCLOSURE)
            )} />
        </MainContainer>
    )
}

export default MandatoryDisclosure
