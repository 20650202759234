import React from 'react';
import swal from 'sweetalert';
import ReactDOM from 'react-dom';
import STATUS from '../Constants';
import { Box } from '@chakra-ui/react';
import Loader from './Loader';

const ConfirmAlert = (title, msg, danger, confirmCallback) => {
  swal({
    title: title || 'Are you sure?',
    text: msg || '',
    icon: 'warning',
    buttons: [true, 'CONFIRM'],
    dangerMode: danger || true,
  }).then((willDelete) => {
    if (willDelete) {
      confirmCallback();
    }
  });
};

const SuccessAlert = (title, msg) => {
  swal({
    title: title || 'This is done ',
    text: msg || '',
    icon: 'success',
  });
};

const ErrorAlert = (title, msg) => {
  swal({
    title: title || 'Our Bad !',
    text: msg || '',
    icon: 'error',
  });
};

const FetchingAlert = (msg) => {
  const wrapper = document.createElement('div');

  ReactDOM.render(<ProcessingAlert />, wrapper);
  const el = wrapper.firstChild;
  swal({
    title: msg,
    content: el,
    button: false,
    closeOnClickOutside: false,
  });
};

const ProcessingAlert = props => (
  <Box p={3}>
    <Loader/>
  </Box>
);

const CheckAlerts = (prev, current) => {
  if (current.toaststatus != prev.toaststatus) {
    if (current.toaststatus == STATUS.FETCHING) {
      FetchingAlert(current.toastmessage);
    } else if (current.toaststatus == STATUS.SUCCESS) {
      SuccessAlert(current.toastmessage);
    } else if (current.toaststatus == STATUS.FAILED) {
      ErrorAlert(current.toastmessage);
    }
  }
};

export { SuccessAlert, FetchingAlert, ConfirmAlert, ErrorAlert, CheckAlerts };
