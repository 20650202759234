import { Box, Button, Heading, Image, useTheme, HStack } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import getData, { keys } from '../data';
import { NewLink } from '../data/Path';
import paths from '../data/Path';

const   HomeIntro = props => {
  const history = useHistory();
  const theme = useTheme();

  let images = _.chain(getData(props.data.school, keys.GALLERY))
    .filter(val => val.images && val.images.length)
    .flatMap(val => val.images)
    .filter((val, i) => i <= 1)
    .value()

  return (
    <Box pt={{base:'40px', md:"80px"}} pb="20px" >
      <HStack px={{ base: 1, md:6, lg:10 }}  flexDir={{ base: 'column-reverse', md:'column-reverse',lg:'row' }} w="100%">
        <Box w={['100%', '40%']} pt={20} display={{base:'none', md:'none', lg:'block'}} >

          {/* <Image src="/images/introLogo.png" style={{display:'block', marginRight:'auto', marginLeft:'auto'}} /> */}
          <Box w={'100%'} h={'100%'} maxH={300} >
            <Image src={images[0].image || '/images/facilitieslogo.png'} objectFit='cover'/>
          </Box>
          {/* <Box p={4} pos='absolute' left='26%' top='43%' height='300px' w='400px' transform='rotate(-9deg)' bg='white'>
                <Image src={images[1].image ||'/images/facilitieslogo.png'} w='100%' objectFit='cover' zIndex='1' pos='relative'/>
              </Box> */}

        </Box>
        <Box pl={{ base: 0, md:6, lg:10 }}  w={{ base: '100%', sm: '100%', lg: '60%',md:'100%' }} >
          <Heading fontSize={{ base: 30, md: 40 }} color={theme.primary}>
            About Us
          </Heading>
          <Image src="/images/Shape9.svg" />
          <br />
          <Box w={'100%'} h={'100%'} display={{base:'block', md:'block', lg:'none'}} >
            <Image src={'http://localhost:3000/images/3_13_s.jpg'
              // images[0].image || '/images/student-image.jpg
              // '
            } objectFit='cover'/>
          </Box>
          <Box className="into-text-ellipsis" pt={{base:4, md:4, lg:0}} >
            <Box dangerouslySetInnerHTML={{ __html: getData(props.data.school, keys.INTRO) }}></Box>
            
          </Box>
          <br />
          <Button
            bg={theme.secondary}
            _hover={{bgColor:theme.secondary,color:theme.primary}}
            w="150px"
            color="white"
            borderRadius="22px"
            onClick={() => NewLink(history, paths.ABOUTUS)}
          >
            Read More
          </Button>
        </Box>
      </HStack>
    </Box>
  );
};

export default HomeIntro;
