import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Text
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import MainContainer from '../components/MainContainer';
import getData, { keys } from '../data';
import _ from 'lodash';
import { Facilities, getFacilities } from '../data/Facilities';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';

const FacilitiesIntro = (props) => {
  // customcomponent is made to provide link on schoollog text
  let customContent = "";
  if (props.title.toLowerCase().includes("schoollog")) {
    customContent = (
      <Text fontSize="18px" textAlign="justify">
        <Link color="#4299e1" isExternal="true" href="https://schoollog.in/">
       
          {props.content.split(" ")[0]}
        </Link>
        {props.content.toLowerCase().replace("schoollog", "")}
      </Text>
    );
  }
  return (
    <Flex p={4} flexWrap="wrap">
      <Box w={{ base: '100%', sm: '100%', lg: '75%' }} p={{base:0, md:6}}>
        <Heading as="h3" size="lg">
          {props.title}
        </Heading>
        <br />
        {customContent ? (
          customContent
        ) : (
        <Text fontSize="18px" textAlign="justify">
          {props.content}
        </Text>
        )}
      </Box>
      <Box w={{ base: '100%', sm: '100%', lg: '25%' }} pos="relative" p={6}>
        <Image display='block' marginRight='auto' marginLeft='auto'
          maxWidth='200px'
          width="100%"
          src={props.image ? props.image : props.defaultimage}
        />
      </Box>
    </Flex>
  );
};

const AllFacilities = props => {
  const history = useHistory()
  useEffect(() => {
    if(history.location.hash)
      $('html, body').animate(
        {
          scrollTop: $(history.location.hash).offset().top - 150
        },
        700
      );  
  })

  const scrollToFacility = fac => {
    $('html, body').animate(
      {
        scrollTop: $(`#section-${fac.id}`).offset().top - 150
      },
      700
    );
  };

  const customFacilities = getData(props.data.school, keys.CUSTOM_FACILITIES)
  const facilities = getFacilities(getData(props.data.school, keys.FACILITIES), customFacilities);
  const facilitiesImages = getData(props.data.school, keys.FACILITIES_IMAGES);
  return (
    <MainContainer heading="Facilities">
      <Flex flexWrap="wrap" id="container">
        <Box w={{ base: '100%', sm: '100%', lg: '25%' }} px={{base:0, md:4}} mb={{base:10, md:0}}>
          <List pos="sticky" top="130px">
            {facilities.map((f, i) => (
              <ListItem
                key={i}
                cursor="pointer"
                onClick={() => scrollToFacility(f)}
                border="1px solid rgba(0,0,0,.125)"
                borderTopLeftRadius={i == 0 ? '.25rem' : ''}
                borderTopRightRadius={i == 0 ? '.25rem' : ''}
                borderBottomLeftRadius={
                  facilities.length == i + 1 ? '.25rem' : ''
                }
                borderBottomRightRadius={
                  facilities.length == i + 1 ? '.25rem' : ''
                }
                fontWeight="bold"
                p=".45rem 1.25rem"
              >
                {f.title}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box w={{ base: '100%', sm: '100%', lg: '75%' }}>
          {_.map(facilities, (f, i) => {
            const fImage = _.find(facilitiesImages, val => val.id == f.id);
            return (
              <section id={`section-${f.id}`} key={`section-${f.id}`}>
                <Box>
                  <FacilitiesIntro
                    title={f.title}
                    content={f.content}
                    image={fImage && fImage.image ? fImage.image : f.image}
                    defaultimage={f.image}
                  />
                </Box>
                {facilities.length == i + 1 ? null : <hr />}
              </section>
            );
          })}
        </Box>
      </Flex>
    </MainContainer>
  );
};

export default AllFacilities;
