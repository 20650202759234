import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import _ from "lodash";

const PrintCareerForm = (props) => {
    const data = props.data
    return (
        <Box>
            <Box>
                <Text style={{fontSize:'22px'}} fontWeight='bold'>Application Details</Text>
            </Box>
            <br/>
            <Box>
              {console.log('contact', data)}
                <Input label="Name" value={data.name} />
                <Input label="Gender" value={data.gender} />
                <Input label="Father Name" value={data.father_name} />
                <Input label="Date Of Birth" value={data.dob} />
                <Input label="Contact 1" value={data.contact1} />
                <Input label="Contact 2" value={data.contact2} />
                <Input label="Email" value={data.email} />
                <Input label="Applied For Subjects" value={_.join(data.subject_apply, ', ')} />
                <Input label="Qualification" value={data.qualification} />
                <Input label="Institute" value={data.institute} />
                <Input label="Board" value={data.board} />
                <Input label="Marks/Grade" value={data.marks} />
                <Input label="Experience" value={`${Math.trunc(data.experience / 12)} Years ${data.experience % 12} Months`}/>
                <Input label="Organisation" value={data.organisation_address} />
                <Input label="Designation" value={data.designation} />
                <Input label="Personal Achievements" value={data.personal_achievements} />
            </Box>
        </Box>
    )
}

const Input = props => (
    <Flex style={{fontSize:'18px'}}>
      <Box width='35%'>
        <Text fontWeight='bold'>{props.label}</Text>
      </Box>
      <Box>
        <Text>{props.value || '-'}</Text>
      </Box>
    </Flex>
  );

export default PrintCareerForm
