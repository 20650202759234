import React from "react";
import getData, { keys } from "../../data";
import { Link as RLink } from "react-router-dom";
import { HStack, Text, Box, Image, Link, useTheme } from "@chakra-ui/react";
const MissionVision = (props) => {
  let vision = getData(props.props.data.school, keys.VISION);
  let mission = getData(props.props.data.school, keys.MISSION);
  let values = getData(props.props.data.school, keys.VALUES);
  const theme = useTheme()
  return (
    <div>

      {(vision || mission || values)?
      <HStack
      // bg="linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(226,222,222,1) 20%, rgba(226,222,222,1) 82%, rgba(255,255,255,1) 100%);"
      // bg="#faebd7"

      mb="0px"
      py={["20px", "50px"]}
      px={["1em", "3em"]}
    >
      {/* w={["100%", "60%"]} */}
      <Box w="full" >
        <Text

          fontSize={["xl", "4xl", "2xl", "4xl"]}
          px="5px"
          fontWeight="bolder"
        >
          VISION,MISSION AND VALUES
        </Text>
        {vision ? (
          <>
            <Text

              fontSize={["20px", "xl", "xl", "2xl"]}
              my="10px"
              px="5px"
              color={theme.primary}
              fontStyle="italic"
              fontWeight="bold"
            >
              VISION
            </Text>
            <Text
              px="5px"
              fontSize={["1xl", "lg", "lg", "lg"]}
              noOfLines={[4, 5]}
            >
              {vision}
            </Text>
            <Link
              as={RLink}
              px="5px"
              to="/vision-mission"
              color={theme.secondary}
              fontSize={["1xl", "lg", "md", "lg"]}
            >
              Read more
            </Link>
          </>
        ) : null}
        {mission ? (
          <>
            <Text

              fontSize={["20px", "xl", "xl", "2xl"]}
              my="10px"
              px="5px"
              color={theme.primary}
              fontStyle="italic"
              fontWeight="bold"
            >
              MISSION
            </Text>
            <Text
              px="5px"
              fontSize={["1xl", "xl", "lg", "lg"]}
              noOfLines={[4, 5]}
            >
              {mission}
            </Text>
            <Link
              as={RLink}
              px="5px"
              to="/vision-mission"
              color={theme.secondary}
              fontSize={["1xl", "lg", "md", "lg"]}
            >
              Read more
            </Link>
          </>
        ) : null}
        {values ? (
          <>
            <Text

              fontSize={["20px", "xl", "xl", "2xl"]}
              my="10px"
              px="5px"
              color={theme.primary}
              fontStyle="italic"
              fontWeight="bold"
            >
              VALUES
            </Text>
            <Text
              px="5px"
              fontSize={["1xl", "lg", "lg", "lg"]}
              noOfLines={[4, 5]}
            >
              {values}
            </Text>
            <Link
              as={RLink}
              px="5px"
              to="/vision-mission"
              color={theme.secondary}
              fontSize={["1xl", "lg", "md", "lg"]}
            >
              Read more
            </Link>
          </>
        ) : null}
      </Box>
      {/* <Image
        mx={"auto"}
        w={["0", "auto", "400px", "40%"]}
        d={["none", "block"]}
        src="/images/mom.png"
      /> */}
    </HStack>
      
      :null}
      
    </div>
  );
};

export default MissionVision;
