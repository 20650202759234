import { Box, Flex, Wrap } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { MONTHS, MONTH_DAYS, YEARS } from '../Constants'

export const CustomDatePicker = ({getDate}) => {
  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  useEffect(() => {
    getDate && getDate(`${day.value}-${month.value || '-'}-${year.value || '-'}`)
  }, [day, month, year])

  return(
    <Wrap >
      <Box w={{base:'100%', md:'32%'}} pr='5px' pb='5px'><Select onChange={d => setDay(d)} options={MONTH_DAYS.map(d => ({label:d, value:d}))} placeholder='Day'/></Box>
      <Box w={{base:'100%', md:'32%'}} pr='5px' pb='5px'><Select onChange={d => setMonth(d)} options={MONTHS.map(d => ({label:d.name, value:d.month}))} placeholder='Month'/></Box>
      <Box w={{base:'100%', md:'32%'}} pb='5px'><Select onChange={d => setYear(d)} options={YEARS.map((d, i) => ({label:d, value:d}))} placeholder='Year'/></Box>
    </Wrap>
  )
}