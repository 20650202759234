import { Box, Image, Text } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
import getData, { convertHTMLString, keys } from '../data';

const VisionAndMission = props => {
  const theme = useTheme()
  return (
    <>
      <Box fontSize="18px" minHeight='400px' color="#34495E" p={{base:5, md:14}} position="relative">
        <Box>
          <Box textAlign='center'>
            <Text fontSize={{base:'3xl', md:'4xl'}} textAlign="center" color={theme.primary}>
              Mission
            </Text>
            <Image marginLeft='auto' width={{base:40, sm:50, md:60}} marginRight='auto' display='block' src="/images/Shape9.svg" />
          </Box>
          <Box
            dangerouslySetInnerHTML={convertHTMLString(
              getData(props.data.school, keys.MISSION) || ''
            )}
          />
        </Box>
        <br/><br/><br/>

        <Box textAlign='center'>
          <Text fontSize={{base:'3xl', md:'4xl'}} textAlign="center" color={theme.primary}>
            Vision
          </Text>
          <Image marginLeft='auto' width={{base:40, sm:50, md:60}} marginRight='auto' display='block' src="/images/Shape9.svg" />
        </Box>
        <Box
          dangerouslySetInnerHTML={convertHTMLString(
            getData(props.data.school, keys.VISION) || ''
          )}
        />
      </Box>
    </>
  );
};

export default VisionAndMission;
