import { Box, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { filter, find, map, sortBy, replace } from "lodash";
import { META_KEY_TYPES } from "../Constants";
import moment from "moment";
import { SchoolHeader } from "./AdmissionFormPrint";
// import { getCurrentSession } from "../../utils/Helper";

export default function CustomEnquiryFormPrint(props) {
    let data = props.data.enquiry
    let school = props.data.school
    let studentSectionMetaKeys = props.data.studentSectionMetaKeys
    const enquirySession = props.data.school.enquirySettings?.data?.enquirySession

    const studentData = data ? {
        name: data.name,
        email: data.email,
        srno: data.srno,
        gender: data.gender,
        dob: data.dob?.date ? moment(data.dob.date).format("DD-MM-YYYY") : '',
        standard_id: data?.standard?.data?.name,
        // dor: data.dor?.date ? moment(data.dor.date).format("DD-MM-YYYY") : '',
        student_contact: data.student_contact,
        address: data.address,
        state_id: data?.state?.data?.name,
        city: data.city,
        pin: data.pin,
        father_name: data?.name,
        father_contact: data.father_contact,
        father_occupation: data.father_occupation,
        mother_name: data.mother_name,
        mother_contact: data.mother_contact,
        mother_occupation: data.mother_occupation,
        adhar_no: data.adhar_no,
        religion: data.religion,
        nationality: data.nationality,
        category: data.category,
        origin: data.origin === "0" ? "WALKIN" : "1" ? "PHONE" : "2" ? "ONLINE" : "3" ? "APP" : '',
        medium: data.medium,
        last_class: data.last_class,
        last_school: data.last_school,
        last_school_percent: data.last_school_percent,
        last_school_result: data.last_school_result,
        entrance_result: data.entrance_result
    } : {}

    return (
        <VStack spacing={"none"}>
            <SchoolHeader school={school} showLogo showAddress />
            {enquirySession ?
                <Box m={0} fontWeight={"bold"}>Session: {replace(enquirySession, "_", "-")}</Box>
                : null
            }
            <Box fontSize="24px" fontWeight={"bold"}>Enquiry Form</Box>
            <VStack w="100%" spacing={3} display={'inline'}>
                {map(studentSectionMetaKeys, sec => (
                    <Flex>
                        <Box key={sec.id} pb={4} w={"100%"}>
                            <Text fontSize={18} fontWeight="bold" color="black">{sec.title}</Text>
                            <Flex w="100%" flexWrap={"wrap"}>
                                {map(filter(sortBy(sec.studentMetaKeys?.data, [function (o) { return o.order; }]), d => d.enquiry_form_visible), (key, i) => {
                                    const width = key.config?.enquiry_row_width === 1 ? "100%" : key.config?.enquiry_row_width === 2 ? "100%" : key.config?.enquiry_row_width === 3 ? "50%" : "50%"
                                    return (
                                        <>
                                            {data ?
                                                <Flex key={key.id} w={width} pr={14}>
                                                    <Text m={0} w={width === "100%" ? "20%" : "40%"}>{key.key}</Text>
                                                    <Text m={0} h="20px" w={width === "100%" ? "80%" : "60%"} mr={5} borderBottom={"1pt dashed black"}>
                                                        {key.default_key ?
                                                            studentData[key.default_key] : find(data?.metaData?.data, d => d.student_meta_key_id === key.id)?.value}</Text>
                                                </Flex>
                                                :
                                                null
                                            }
                                        </>
                                    )
                                })}
                            </Flex>
                        </Box>
                    </Flex>
                ))}
            </VStack>
            {/* <Flex w="100%" justify={"space-between"} m={0} mt={"60pt"} fontWeight={"semibold"}>
                <Text>Student Signature</Text>
                <Text>Parent/Guardian Signature</Text>
                <Text>Principal Signature</Text>
            </Flex> */}
        </VStack>
    )
}