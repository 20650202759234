import React from "react";
import ImageBackgroundCarousel from "./ImageBackgroundCarousel";
import ViewCards from "./ViewCards";
import Profile from "./Profile";
import FacilitiesCarousel from "./FacilitiesCarousel";
import PhotoGallery from "./PhotoGallery";
import EnrollNow from "./EnrollNow";
import Testimonials from "./Testimonials";
import MissionVision from "./MissionVision";
import Map from "./Map";
import News_Events from "./News_Events";
const Home4 = (props) => {
  return (
    <div>
      <ImageBackgroundCarousel props={props} />
      <ViewCards props={props}/>
      <Profile props={props} />
      <FacilitiesCarousel props={props} />
      <PhotoGallery props={props} />
      <News_Events props={props} />
      <EnrollNow props={props}/>
      <Testimonials props={props} />

      <MissionVision props={props} />
      {props.data.school.website?.data?.map_embed_link?<Map data={props} />:null}
      
    </div>
  );
};

export default Home4;
