import React, { useState } from "react";
// import "@fontsource/jaldi";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  VStack,
  Spacer,
  Text,
  useTheme,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { BsChevronDown, BsFillEnvelopeFill } from "react-icons/bs";
import { NewLink } from "../data/Path";
import { useHistory } from "react-router-dom";
import getData, { keys } from "../data";
import _ from "lodash";
import paths from "../data/Path";
import MobileHeader from "./MobileHeader";
import {
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaWhatsapp,
  FaInstagram
} from 'react-icons/fa';
import {
  AiFillPhone,
  AiFillMail,
  AiTwotoneEnvironment,
} from "react-icons/ai";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Popup from "../routes/WebsitePopup";
import { useSelector } from 'react-redux';

const Header = (props) => {
  const popupData = useSelector(state => state.school.website?.data.popup)
  // const templateWeb = useSelector(state => state.school.website?.data.website_template)
  // console.log({templateWeb, props})
  return (

    <>
      <Box w="100%" className="browserHeader">
        {props.templateDesign == 2 || props.templateDesign == 1 || props.templateDesign == 3 ?
          <ContactDetails {...props} />
          :
          null
        }
      </Box>
      {
        props.templateDesign == 5 ?
          <SchoolName5 {...props} />
          :
          null
      }
      <Box
        position="sticky"
        className="browserHeader"
        top={0}
        right={0}
        zIndex="10"
        left={0}
      >
        {popupData?.visible == 1 ? <Popup /> : null}
        {props.templateDesign == 5 ?
          null

          :
          <SchoolName {...props} />
        }

        {/* <hr/> */}
        {
          props.templateDesign == 0 ?
            <SchoolMenu menuData={props.data.menu}  />
            :
            props.templateDesign == 5 ?
              <SchoolMenu5 menuData={props.data.menu} schoolData={props}/>
              :
              <SchoolMenu2 menuData={props.data.menu} />
        }

      </Box>
      <MobileHeader {...props} />
    </>
  );
};

const SchoolMenu = ({ menuData }) => {
  const [activeTab, setActiveTab] = useState("");
  let theme = useTheme();
  return (
    <Flex
      w="100%"
      fontWeight="bold"
      justifyContent="space-evenly"
      fontSize="13px"
      bg={theme.secondary}
    >
      {menuData.map((tab, i) => (
        <MenuTab
          dropdown={tab.children.length > 1}
          key={i}
          tab={tab}
          childrens={tab.children}
          active={activeTab == tab.menu.id}
          selectTab={() => setActiveTab(tab.menu.id)}
          title={tab.menu.name}
        />
      ))}
    </Flex>
  );
};

const MenuTab = ({ title, dropdown, childrens, active, tab }) => {
  let history = useHistory();
  let theme = useTheme();
  return (
    <Menu bg="white">
      <MenuButton
        onClick={() => (dropdown ? null : NewLink(history, childrens[0].path))}
        rightIcon={dropdown ? <BsChevronDown /> : null}
        borderBottom={"4px solid " + theme.secondary}
        size="sm"
        p="20px"
        m={0}
        as={Button}
        bg={theme.secondary}
        transition="0.2s"
        isActive={active}
        borderRadius={0}
        _hover={{
          color: "white",
          cursor: "pointer",
          borderBottomColor: "white",
          background: theme.secondary,
        }}
        _active={active ? { background: theme.secondary, color: "white" } : {}}
      >
        {title}
      </MenuButton>
      {dropdown ? (
        <MenuList color="black">
          {childrens.map((children) => (
            <MenuItem
              onClick={() => NewLink(history, children.path)}
              key={children.id}
              fontSize="16px"

            >
              {children.name}
            </MenuItem>
          ))}
        </MenuList>
      ) : null}
    </Menu>
  );
};

const SchoolMenu2 = ({ menuData }) => {
  const [activeTab, setActiveTab] = useState("");
  let theme = useTheme();
  return (
    <Flex
      w="100%"
      fontWeight="bold"
      justifyContent="space-evenly"
      fontSize="13px"
      bg='white'
      borderTop='2px solid'
      borderColor='gray.200'
      boxShadow='lg'
      // borderBottom='2px solid #E2E8F0'

      py='4px'
    >
      {menuData.map((tab, i) => (
        <MenuTab2
          dropdown={tab.children.length > 1}
          key={i}
          tab={tab}
          childrens={tab.children}
          active={activeTab == tab.menu.id}
          selectTab={() => setActiveTab(tab.menu.id)}
          title={tab.menu.name}
        />
      ))}
    </Flex>
  );
};
const SchoolMenu5 = ({ menuData, schoolData }) => {
  const [activeTab, setActiveTab] = useState("");
  let theme = useTheme();
  let history = useHistory();
  return (
    <Flex
      w="100%"
      fontWeight="bold"
      justifyContent="space-evenly"
      fontSize="13px"
      bg='white'
      borderTop='2px solid'
      borderColor='gray.200'
      boxShadow='lg'
      // borderBottom='2px solid #E2E8F0'

      py='4px'
    >
      <Box pr={3} display={{base:'none', md: 'none', lg:'block'}}>
        <Image
          cursor="pointer"
          onClick={() => NewLink(history, paths.HOME)}
          src={getData(schoolData.data.school, keys.LOGO)}
          h="50px"
          alt={'logo'}
        />
      </Box>
      {menuData.map((tab, i) => (
        <MenuTab5
          dropdown={tab.children.length > 1}
          key={i}
          tab={tab}
          childrens={tab.children}
          active={activeTab == tab.menu.id}
          selectTab={() => setActiveTab(tab.menu.id)}
          title={tab.menu.name}
        />
      ))}
    </Flex>
  );
};
const MenuTab5 = ({ title, dropdown, childrens, active, tab }) => {
  let history = useHistory();
  let theme = useTheme();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Menu bg="white">
      <MenuButton
        // onMouseOver={onOpen}
        // onMouseOut={onClose}
        onClick={() => (dropdown ? null : NewLink(history, childrens[0].path))}
        rightIcon={dropdown ? <BsChevronDown /> : null}
        borderBottom={active ? "4px solid blue.600" : 'none'}
        size="sm"
        py="20px"
        m={0}
        as={Button}
        bg='white'
        // bg={theme.secondary}
        transition="0.2s"
        isActive={active}
        borderRadius={0}
        _hover={{
          color: "blue.600",
          cursor: "pointer",
          borderBottomColor: "blue.600",
          background: 'white',
        }}
        _active={active ? { background: 'gray.100', color: "white" } : {}}
      >
        {title}
      </MenuButton>
      {dropdown ? (
        <MenuList color="black">
          {childrens.map((children) => (
            <MenuItem
              onClick={() => NewLink(history, children.path)}
              key={children.id}
              fontSize="16px"

            >
              {children.name}
            </MenuItem>
          ))}
        </MenuList>
      ) : null}
    </Menu>
  );
};

const MenuTab2 = ({ title, dropdown, childrens, active, tab }) => {
  let history = useHistory();
  let theme = useTheme();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Menu bg="white">
      <MenuButton
        // onMouseOver={onOpen}
        // onMouseOut={onClose}
        onClick={() => (dropdown ? null : NewLink(history, childrens[0].path))}
        rightIcon={dropdown ? <BsChevronDown /> : null}
        borderBottom={active ? "4px solid blue.600" : 'none'}
        size="sm"
        p="20px"
        m={0}
        as={Button}
        bg='white'
        // bg={theme.secondary}
        transition="0.2s"
        isActive={active}
        borderRadius={0}
        _hover={{
          color: "blue.600",
          cursor: "pointer",
          borderBottomColor: "blue.600",
          background: 'white',
        }}
        _active={active ? { background: 'gray.100', color: "white" } : {}}
      >
        {title}
      </MenuButton>
      {dropdown ? (
        <MenuList color="black">
          {childrens.map((children) => (
            <MenuItem
              onClick={() => NewLink(history, children.path)}
              key={children.id}
              fontSize="16px"

            >
              {children.name}
            </MenuItem>
          ))}
        </MenuList>
      ) : null}
    </Menu>
  );
};

export const SchoolName5 = (props) => {
  let theme = useTheme();
  let history = useHistory();
  return (
    <HStack flexDir={{ base: 'column', md: 'row' }} px={{ base: 1, md: 10 }} alignItems={{base: 'left' , md:"center"}} bg={'#1d3759'} py={3}>
      {/* <Box pr={3} display={{base:'none', md: 'none', lg:'block'}}>
        <Image
          cursor="pointer"
          onClick={() => NewLink(history, paths.HOME)}
          src={getData(props.data.school, keys.LOGO)}
          w="200px"
        />
      </Box> */}
      {/* <Box>
        <Heading
          cursor="pointer"
          onClick={() => NewLink(history, paths.HOME)}
          color={theme.primary}
          as="h3"
          size="lg"
        >
          {getData(props.data.school, keys.NAME)}
        </Heading>
      </Box> */}
      {/* <Spacer /> */}

      <Flex justify="space-between" flexDir={{ base: 'column', md: 'row' }}>



        {/* <Flex direction="row" m="10px" justify="flex-start" alignItems="flex-start">
          <AiTwotoneEnvironment size={18} style={{ marginTop: '2px' }} color={theme.primary} />
          <Flex m="5px" mt={0} p="0px" direction="column" justify="left">
            <Text
              cursor="pointer"

              onClick={() => NewLink(history, paths.HOME)}
              color={theme.primary}
              as="h6"
              size="sm*"
              m="1px"
            >
              Location
            </Text>
            <Text

              cursor="pointer"
              onClick={() => NewLink(history, paths.HOME)}

              as="h6"
              size="sm"
              p="0px"
            >
              {[getData(props.data.school, keys.CITY), getData(props.data.school, keys.STATE), getData(props.data.school, keys.PINCODE)].filter(d => d).join(', ')}
            </Text>
          </Flex>
        </Flex> */}
        <Flex direction="row" m={{ base: 1, md: "10px" }} justify="flex-start" alignItems="flex-start">
          <AiFillPhone size={18} style={{ transform: 'rotate(90deg)', marginTop: '2px' }} color={'white'} />
          <Text
            cursor="pointer"
            onClick={() => NewLink(history, paths.HOME)}
            color={'white'}
            as="h6"
            size="sm"

            m="1px"
          >
            {/* Phone */}
          </Text>
          <Text
            cursor="pointer"
            color={'white'}
            onClick={() => NewLink(history, paths.HOME)}
            as="h6"
            size="sm"
            p="0px"
            pl={2}

          >
             {
              getData(props.data.school, keys.PRIMARY_CONTACT) ? getData(props.data.school, keys.PRIMARY_CONTACT) : getData(props.data.school, keys.SECONDARY_CONTACT)
            }

            {/* {_.join(_.compact([getData(props.data.school, keys.PRIMARY_CONTACT), getData(props.data.school, keys.SECONDARY_CONTACT)]), '/')} */}
          </Text>
        </Flex>
        <Flex direction="row" m={{ base: 1, md: "10px" }} justify="flex-start" alignItems="flex-start">
          <AiFillMail size={18} style={{ marginTop: '2px' }} color={'white'} />
          <Text
            cursor="pointer"
            onClick={() => NewLink(history, paths.HOME)}
            color={'white'}
            as="h6"
            size="sm"
            pl={2}
            m="1px"
          >
            {/* Email */}
          </Text>
          <Text
            cursor="pointer"
            color={'white'}
            onClick={() => NewLink(history, paths.HOME)}

            as="h6"
            size="sm"
            p="0px"

          >
            {getData(props.data.school, keys.EMAIL)}
          </Text>
        </Flex>

      </Flex>

      {/* <Box>
        {!getData(props.data.school, keys.PRIMARY_CONTACT) ? null : (
          <Button
            variant="outline"
            borderColor={theme.secondary}
            borderRadius={19}
          >
            Call for admission -
            {getData(props.data.school, keys.PRIMARY_CONTACT)}
          </Button>
        )}
      </Box> */}
      <Spacer />
      <Box >
        <HStack mt={2} justify={{base:'start', md:'end'}} spacing={4} >
          {getData(props.data.school, keys.FACEBOOK_LINK) ?
            <Box pr={4}>
              <Link target='_blank' href={getData(props.data.school, keys.FACEBOOK_LINK)}>
                <FaFacebook className='facebook-button5' />
              </Link>
            </Box>
            : null

          }
          {getData(props.data.school, keys.YOUTUBE_LINK) ?
            <Box pr={4}>
              <Link target='_blank' href={getData(props.data.school, keys.YOUTUBE_LINK)}>
                <FaYoutube className='youtube-button5' />
              </Link>
            </Box>
            : null
          }
          {getData(props.data.school, keys.TWITTER_LINK) ?
            <Box pr={4}>
              <Link target='_blank' href={getData(props.data.school, keys.TWITTER_LINK)}>
                <FaTwitter className='twitter-button5' />
              </Link>
            </Box>
            : null
          }
          {getData(props.data.school, keys.WHATSAPP_CONTACT) ?
            <Box pr={4}>
              <Link target='_blank' href={`https://wa.me/91${getData(props.data.school, keys.WHATSAPP_CONTACT)}`}>
                <FaWhatsapp className='whatsaap-button5' />
              </Link>
            </Box>
            : null
          }
          {getData(props.data.school, keys.INSTAGRAM_LINK) ?
            <Box pr={4}>
              <Link target='_blank' href={getData(props.data.school, keys.INSTAGRAM_LINK)}>
                <FaInstagram className='instagram-button5' />
              </Link>
            </Box>
            : null
          }
        </HStack>
      </Box>
    </HStack>

  );
};
export const SchoolName = (props) => {
  let theme = useTheme();
  let history = useHistory();
  return (
    <Flex px={10} alignItems="center" bg="white" py={3}>
      <Box pr={3}>
        <Image
          cursor="pointer"
          onClick={() => NewLink(history, paths.HOME)}
          src={getData(props.data.school, keys.LOGO)}
          w="70px"
        />
      </Box>
      <Box>
        <Heading
          cursor="pointer"
          onClick={() => NewLink(history, paths.HOME)}
          color={theme.primary}
          as="h3"
          size="lg"
        >
          {getData(props.data.school, keys.NAME)}
        </Heading>
      </Box>
      <Spacer />

      <Flex justify="space-between">



        <Flex direction="row" m="10px" justify="flex-start" alignItems="flex-start">
          <AiTwotoneEnvironment size={18} style={{ marginTop: '2px' }} color={theme.primary} />
          <Flex m="5px" mt={0} p="0px" direction="column" justify="left">
            <Text
              cursor="pointer"

              onClick={() => NewLink(history, paths.HOME)}
              color={theme.primary}
              as="h6"
              size="sm*"
              m="1px"
            >
              Location
            </Text>
            <Text

              cursor="pointer"
              onClick={() => NewLink(history, paths.HOME)}

              as="h6"
              size="sm"
              p="0px"
            >
              {[getData(props.data.school, keys.CITY), getData(props.data.school, keys.STATE), getData(props.data.school, keys.PINCODE)].filter(d => d).join(', ')}
            </Text>
          </Flex>
        </Flex>

        <Flex direction="row" m="10px" justify="flex-start" alignItems="flex-start">
          <AiFillMail size={18} style={{ marginTop: '2px' }} color={theme.primary} />
          <Flex m="5px" mt='0px' p="0px" direction="column" justify="left">
            <Text
              cursor="pointer"
              onClick={() => NewLink(history, paths.HOME)}
              color={theme.primary}
              as="h6"
              size="sm"
              m="1px"

            >
              Email
            </Text>
            <Text
              cursor="pointer"
              onClick={() => NewLink(history, paths.HOME)}

              as="h6"
              size="sm"
              p="0px"

            >
              {getData(props.data.school, keys.EMAIL)}
            </Text>
          </Flex>
        </Flex>

        <Flex direction="row" m="10px" justify="flex-start" alignItems="flex-start">
          <AiFillPhone size={18} style={{ transform: 'rotate(90deg)', marginTop: '2px' }} color={theme.primary} />
          <Flex m="5px" mt={0} p="0px" direction="column" justify="left">
            <Text
              cursor="pointer"
              onClick={() => NewLink(history, paths.HOME)}
              color={theme.primary}
              as="h6"
              size="sm"

              m="1px"
            >
              Phone
            </Text>
            <Text
              cursor="pointer"
              onClick={() => NewLink(history, paths.HOME)}
              as="h6"
              size="sm"
              p="0px"

            >
              {
                getData(props.data.school, keys.PRIMARY_CONTACT) ? getData(props.data.school, keys.PRIMARY_CONTACT) : getData(props.data.school, keys.SECONDARY_CONTACT)
              }

              {/* {_.join(_.compact([getData(props.data.school, keys.PRIMARY_CONTACT), getData(props.data.school, keys.SECONDARY_CONTACT)]), '/')} */}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {/* <Box>
        {!getData(props.data.school, keys.PRIMARY_CONTACT) ? null : (
          <Button
            variant="outline"
            borderColor={theme.secondary}
            borderRadius={19}
          >
            Call for admission -
            {getData(props.data.school, keys.PRIMARY_CONTACT)}
          </Button>
        )}
      </Box> */}
    </Flex>

  );
};

const ContactDetails = (props) => {
  let theme = useTheme();
  return (
    <Box w="100%" h="100%" color="white" fontSize={12} p={2} bg={theme.primary}>
      <Flex alignItems="center" h="100%">
        <Flex position="relative" px={4}>
          <Flex alignItems="center" className="header-address">
            <FaMapMarkerAlt fontSize={14} />
            &nbsp; Address: {getData(props.data.school, keys.ADDRESS)},
            {getData(props.data.school, keys.CITY)},
            {getData(props.data.school, keys.STATE)} -
            {getData(props.data.school, keys.PINCODE)}
          </Flex>
        </Flex>
        <Flex position="relative" px={4}>
          {!getData(props.data.school, keys.EMAIL) ? null : (
            <Flex alignItems="center" className="header-email">
              <BsFillEnvelopeFill fontSize={14} />
              &nbsp; {getData(props.data.school, keys.EMAIL)}
            </Flex>
          )}
        </Flex>
        <Flex px={4}>
          {!getData(props.data.school, keys.PRIMARY_CONTACT) &&
            !getData(props.data.school, keys.SECONDARY_CONTACT) ? null : (
            <Flex alignItems="center" className="header-email">
              <FaPhoneAlt fontSize={14} />
              &nbsp;
              {_.join(
                _.compact([
                  getData(props.data.school, keys.PRIMARY_CONTACT),
                  getData(props.data.school, keys.SECONDARY_CONTACT),
                ]),
                " / "
              )}
            </Flex>
          )}
        </Flex>
        <Spacer />
        {_.findIndex(
          getData(props.data.school, keys.FEATURES),
          (val) => val == 40
        ) == -1 && _.findIndex(
          getData(props.data.school, keys.FEATURES),
          (val) => val == 46
        ) == -1 ? null : (
          <>
            {_.findIndex(
              getData(props.data.school, keys.FEATURES),
              (val) => val == 40
            ) == -1 ? null :
              <Flex px={2}>
                <Button
                  onClick={() =>
                    window.open(
                      getData(props.data.school, keys.LOGIN_STUDENT_LINK) ||
                      "http://digital.schoollog.in"
                    )
                  }
                  variant="outline"
                  size="xs"
                  bg="white"
                  color="black"
                  borderRadius={19}
                >
                  Student Login
                </Button>
              </Flex>
            }
            {
              _.findIndex(
                getData(props.data.school, keys.FEATURES),
                (val) => val == 46
              ) == -1 ? null :
                <Flex px={2}>
                  <Button
                    onClick={() =>
                      window.open(
                        getData(props.data.school, keys.LOGIN_ERP_LINK) ||
                        "https://erp.schoollog.in/"
                      )
                    }
                    variant="outline"
                    size="xs"
                    bg="white"
                    color="black"
                    borderRadius={19}
                  >
                    Staff Login
                  </Button>
                </Flex>
            }
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
