import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Box, Text, Image, Link, ModalHeader
} from "@chakra-ui/react"


const Popup = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const popupData = useSelector(state => state.school.website?.data.popup)

	useEffect(() => {
		onOpen()
	}, [])
	return (
		<Modal size='xl' isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				{popupData?.title && popupData?.title.length>0?<ModalHeader py='10px' >
					{popupData?.title ? <Text  fontSize='lg' >{popupData?.title.toUpperCase()}</Text> : null}
				</ModalHeader>:null}
				<ModalCloseButton />
				<ModalBody p='0'>
					{/* <WebsitePopup /> */}
					<Box >
						{popupData?.description ? <Text fontSize='md' px='24px' fontWeight='100'  > {popupData?.description}  </Text> : null}

						{
							popupData?.link ?
								popupData?.attachment ?
									<Link href={popupData.link} isExternal >
										<Image src={popupData.attachment} />
									</Link> :
									null
								: <Image src={popupData.attachment} />

						}
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}


export default Popup
