import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash'
import GenPrint from './common/GenPrint';

export const MONTH_DAYS = Array.from({length: 31}, (_, i) => i + 1).map(n => String(n).padStart(2, '0')) 

export const MONTHS = [
  {id:1, name:'January', month:'01'}, {id:2, name:'February', month:'02'}, 
  {id:3, name:'March', month:'03'}, {id:4, name:'April', month:'04'}, 
  {id:5, name:'May', month:'05'}, {id:6, name:'June', month:'06'}, 
  {id:7, name:'July', month:'07'}, {id:8, name:'August', month:'08'},
  {id:9, name:'September', month:'09'}, {id:10, name:'October', month:'10'},
  {id:11, name:'November', month:'11'}, {id:12, name:'December', month:'12'}
]

export const YEARS = getYears()
function getYears(){
  const currentYear = (new Date()).getFullYear()
  return _.rangeRight(currentYear - 100, currentYear+1) 
}

const STATUS = {
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  NOT_STARTED: 'NOT_STARTED',
  INVALID: 'INVALID'
};

export default STATUS;

export const PRINT_TYPE = {
  ADMISSION_FORM: 1,
  CAREER_FORM: 2,
  ADMISSION_FORM_CUSTOM: 3,
};

export const printHelper = (type, data) => {
  document.getElementById('print').innerHTML = '';
  ReactDOM.render(<GenPrint type={type} data={data} />, document.getElementById('print'));
  setTimeout(() => ReactDOM.unmountComponentAtNode(document.getElementById('print')), 3000)
};

export const META_KEY_TYPES = {
  TEXT: 'text',
  DATE: 'date',
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  DROPDOWN: 'dropdown',
  MULTIPLE_CHOICE: 'multiple_choice'
}
