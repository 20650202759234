import { Box, Button, Flex, FormLabel, HStack, Image, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useDispatch } from 'react-redux';
import MainContainer from '../components/MainContainer';
import getData, { keys } from '../data';
import { CustomDatePicker } from '../helper';
import { getTcAction } from '../redux/actions';
import _ from 'lodash'

const TransferCertificate = props => {
    const dispatch = useDispatch()
    const [choosen, chooseOption] = useState('TC')
    const [isOpen, openLightBox] = useState(false)
    const [images, setImages] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0)

    const match = (e) =>{
        e.preventDefault();
        dispatch(getTcAction(new FormData(e.target)))
    }

    const openTc = (data) => {
        openLightBox(true)
        setImages(data.tc)
        setPhotoIndex(0)
    }

    const closeLightBox = () => {
        openLightBox(false)
        setImages([])
    }

    return (
        <MainContainer heading="Transfer Certificate">
            <br/>
            <Flex flexWrap="wrap" justifyContent='center'>
                <Flex flexDir='column' alignItems='center' w={{ base: '100%', sm: '100%', lg: '50%' }}>
                    <Stack direction={["column", "row"]} spacing="24px" mb={{base:10, md:0}}>
                        <FormLabel><b>Select Search Type:</b></FormLabel>
                        <RadioGroup defaultValue="TC" onChange={chooseOption}>
                            <Stack direction={["column", "row"]} spacing={5}>
                                <Radio name="optradio" sele value="TC">Search By SR Number</Radio>
                                <Radio name="optradio" value="SR">Search By TC Number</Radio>
                            </Stack>
                        </RadioGroup>
                    </Stack>
                    <br/>
                    {choosen == 'TC' ?
                        <SearchBox school={props.data.school} name='srno' match={match}/>
                        :choosen == 'SR' ?
                            <SearchBox school={props.data.school} name='tcno' match={match}/>
                            : null
                    }
                </Flex>
            </Flex>
            <br/><br/>
            <Flex flexWrap="wrap" justifyContent='center'>
                
                {props.data.tcList && props.data.tcList.length ? 
                    props.data.tcList.map((tc, i) =>
                        <Box key={i} w={{ base: '100%', sm: '100%', lg: '25%' }}>
                            <Flex pos='relative' border='1px solid #D6DBDF' alignItems='center' borderRadius='8px' cursor='pointer' onClick={() => openTc(tc)}>
                                <Box p={4}>
                                    <Image src={'/images/certificate.png'} maxW='80px'/>
                                </Box>
                                <Box className='birthday-card-container' fontSize='md' p={2}>
                                    <Text><b>TC Number:</b> {tc.tcno}</Text>
                                    <Text><b>Sr Number:</b> {tc.srno}</Text>
                                    <Text><b>Date of Birth:</b> {tc.tcno ? _.trim(tc.dob.date, '00:00:00.000000') : null}</Text>
                                </Box>
                            </Flex>
                        </Box>
                    )
                    :
                    null
                }
            </Flex>
            {isOpen && (
                <Lightbox
                    imagePadding={50}
                    mainSrc={images}
                    onCloseRequest={closeLightBox}
                />
            )}
        </MainContainer>
    );
};

const SearchBox = ({school, name, match}) => {
    const [dob, setDob] = useState('')
    return(
        <Box className="birthday-card" py={6} px={10} borderRadius='5px'>
            <Text fontSize='l' textAlign='center' fontWeight='bold'>Search by {name == 'srno' ? 'Sr. Number' : 'TC Number'}</Text>
            <br/>
            <form key="srno-form" className="form" onSubmit={match}>
                <Stack spacing={5}>
                    <Input placeholder={name == 'srno' ? 'Sr. Number' : 'TC Number'} textAlign='center' name={name}/>
                    <Stack direction={["column", "row"]}>
                        <Box w='220px' textAlign={{base:'center', md:'left'}}>
                            Date of Birth:
                        </Box>
                        <Box w='100%'>
                            <CustomDatePicker getDate={d => setDob(d)}/>
                        </Box>
                    </Stack>
                    <Input type="hidden" value={getData(school, keys.ID)} name="school_id"/>
                    <Input type='hidden' name="dob" value={dob}/>
                    <Button colorScheme='blue' type='submit'>Search</Button>
                </Stack>
            </form>
        </Box>
    )
}

export default TransferCertificate