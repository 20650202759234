import { Box } from '@chakra-ui/react';
import React from 'react';
import MainContainer from '../components/MainContainer';
import getData, { convertHTMLString, keys } from '../data';

const AboutUs = props => {
  return (
    <MainContainer heading="About School">
      <Box
        dangerouslySetInnerHTML={convertHTMLString(
          getData(props.data.school, keys.ABOUT_SCHOOL)
        )}
      />
    </MainContainer>
  );
};

export default AboutUs;
