import { Box, Button, Heading, Image, useTheme } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import getData, { keys } from '../data';
import { NewLink } from '../data/Path';
import paths from '../data/Path';

const HomeIntro = props => {
  const history = useHistory();
  const theme = useTheme();

  let images = _.chain( getData(props.data.school, keys.GALLERY))
            .filter(val => val.images && val.images.length)
            .flatMap(val => val.images)
            .filter((val, i) => i <= 1)
            .value()

  return (
    <Box pt="80px" pb="20px">
      <Box style={{display:'flex'}} w="100%">
        <Box className='schoolImages' style={{textAlign:'center'}} w='50%'>
          
              <Image src="/images/introLogo.png" style={{display:'block', marginRight:'auto', marginLeft:'auto'}} />
              {/* <Image src={images[0].image || '/images/facilitieslogo.png'} objectFit='cover' position='absolute' w='360px' left='4%' top='7%' height='260px' transform='rotate(4deg)'/>
              <Box p={4} pos='absolute' left='26%' top='43%' height='300px' w='400px' transform='rotate(-9deg)' bg='white'>
                <Image src={images[1].image ||'/images/facilitieslogo.png'} w='100%' objectFit='cover' zIndex='1' pos='relative'/>
              </Box> */}
          
        </Box>
        <Box px={{base:6, md:20}} w={{ base: '100%', sm: '100%', lg: '60%' }}>
            <Heading fontSize={{base:30, md:40}} color={theme.primary}>
              An Introduction
            </Heading>
            <Image src="/images/Shape9.svg" />
            <br />
            <Box className="into-text-ellipsis">
              <Box dangerouslySetInnerHTML={{__html:getData(props.data.school, keys.INTRO)}}></Box>
            </Box>
            <br />
            <Button
              bg={theme.secondary}
              w="150px"
              color="white"
              borderRadius="22px"
              onClick={() => NewLink(history, paths.ABOUTUS)}
            >
              Read More
            </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeIntro;
