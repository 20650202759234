import React from "react";
import getData, { keys } from "../../data";
import Path, { NewLink } from "../../data/Path"


import {
  Box,
  Spacer,
  Flex,
  Text,
  HStack,
  Image,
  useBreakpointValue,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useTheme
} from "@chakra-ui/react";
import _ from "lodash";
import { useHistory } from "react-router";

const PhotoGallery = (props) => {
  const theme=useTheme()
  const history=useHistory()
  const buttonSize = useBreakpointValue(["xs", "sm", "md", "md"]);
  let galleryData = getData(props.props.data.school, keys.GALLERY);
  let images = [];
  galleryData.map((album) => {
    if (album.images) {
      album.images.map((image) => {
        images.push(image.image);
      });
    }
  });

  let slides = _.chunk(_.sampleSize(images, 6), 3);

  return (
    <Box
      w={["90%", "90%", "90%", "80%"]}
      mx="auto"
    >
      <Flex mx="auto" >
        <Box
          m={("auto", "10px", "5px", "10px", "0px")}
          my="auto"
          p="5px"
        >
          <Text
            fontSize={["3xl", "4xl", "4xl", "4xl"]}
            fontWeight="bolder"
          >
            Photo Gallery
          </Text>
          <Text
            textAlign="left"
            fontSize={["1em", "18px", "1xl", "2xl"]}
            color='gray.600'
          >
            Enjoy our selection of pictures of children, staff and facilities
          </Text>
          <Button
            bg={theme.primary}
            color='white'
            onClick={() => NewLink(history, Path.GALLERY)}
            my="5px"
            w="60%"
            my="2em"
            // p="1em"
            size={buttonSize}
            _hover={{
              opacity:'0.8'
            }}
          >
            View Gallery
          </Button>
        </Box>
        <Spacer />
        <Flex w="70%" direction="column" justify="center" >
          {slides.map((slide, sid) => (
            <HStack m="0px" key={sid} spacing={["2px", "2px", "2px", "5px"]}>
              <Image
                objectFit="cover"
                maxWidth={["20", "20", "14em", "50%", "17em"]}
                h={["20", "20", "60", "60", "15em"]}
                m={["2px", "1px", "3px", "5px"]}
                src={slide[0]}
                flex='1'
              />
              <Image
                objectFit="cover"
                m={["2px", "1px", "3px", "5px"]}
                maxWidth={["20", "20", "14em", "50%", "17em"]}
                h={["20", "20", "60", "80", "15em"]}
                src={slide[1]}
                flex='1'
              />
              <Image
                objectFit="cover"
                d={["none", "none", "none", "block"]}
                m={["2px", "1px", "3px", "5px"]}
                maxWidth={["20", "20", "14em", "50%", "17em"]}
                h={["20", "20", "60", "80", "15em"]}
                src={slide[2]}
                flex='1'
              />
            </HStack>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default PhotoGallery;
