import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import getData, { keys } from "../../data";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import {
  Flex,
  Box,
  Link,
  Text,
  Avatar,
  chakra,
  HStack,
  Icon,
  VStack,
  useColorModeValue,
  useTheme
} from "@chakra-ui/react";
import "./code.css";

const Testimonials = (props) => {
  const testimonials = getData(props.props.data.school, keys.TESTIMONIALS);
  const theme=useTheme()
  const breakPoints = [
    { width: 350, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 2 ,itemsToScroll: 2 },
    { width: 1200, itemsToShow: 2 ,itemsToScroll: 2 },
  ];


  return (
    <div mb="0px">
      {testimonials && testimonials.length>1? 
      <Box w="80%" my="1px" mx="auto" py="40px">
        <Text
          mt="40px"
          fontStyle="italic"
          textAlign="center"
          fontSize={["1xl", "1xl", "15px", "xl"]}
        >
          WHAT DO PARENT SAY ABOUT US
        </Text>
        <Text
          fontSize={["2xl", "3xl", "3xl", "4xl"]}
          textAlign="center"
          px="5px"
        >
          Testimonials
        </Text>
        <Text
          fontSize={["1xl", "2xl", "1.2em", "1xl"]}
          textAlign="center"
          px={["20px", "20px", "20px", "200px","200px","400px"]}
          py="10px"
        >
          Let's explore what parent says about us.Let us know what you think of
          today's newsletter and what you would like to.
        </Text>

        <Carousel style={{
          padding: "0px"
        }} breakPoints={breakPoints}>
          { 
          
          testimonials.map((slide, sid) => (
            <Box  >
              <Box key={`slide-${sid}`} boxSize="full" flex="none">
                <Box
                
                  boxSize="full"
                  backgroundSize="cover"
                >
                  <Box
           
                    w={["12em", "md", "34em", ,"28em","28em","34em"]}
                    bg={useColorModeValue("white", "gray.800")}
                    shadow="2xl"
                    rounded="lg"
                    m={["5px", "10px"]}
           
                   h={["18em","22em"]}
                    px={["1em", "2em"]}
                    py={["1em", "1.5em"]}
                  >
                    <HStack ml="0.5em">
                      <Avatar
                        m={["1px", "4px"]}
                        borderRadius="full"
                        boxSize={["50px", "40px", "100px"]}
                        fontSize={["1xl", "2xl", "25px", "3xl"]}
                        src={`${slide.image}`}
                        fallbackSrc={`https://via.placeholder.com/500x700.png?text=${slide.title}`}
                      />
                      <Box>
                        <Box
                          fontSize={["3", "1xl", "2xl"]}
                          fontWeight="bold"
                          color={useColorModeValue("gray.800", "white")}
                        >
                          {slide.title}
                        </Box>
                        <HStack
                          spacing={1}
                          mt={2}
                        >
                          <Icon as={AiFillStar} color={theme.secondary} />
                          <Icon as={AiFillStar} color={theme.secondary} />
                          <Icon as={AiFillStar} color={theme.secondary} />
                          <Icon as={AiFillStar} color={theme.secondary} />
                          <Icon as={AiFillStar} color={theme.secondary} />
                        </HStack>
                      </Box>
                    </HStack>


                    <chakra.p
                      fontSize="sm"
                      color={useColorModeValue("gray.600", "gray.400")}
                      pt="1em"
                    noOfLines="8"
                    >
                      {slide.description}
                    </chakra.p>

                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>:null}
    </div>
  );
};
export default Testimonials;
