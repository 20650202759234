import React from "react";
import {
  Box,
  Text,
  Flex,
  Image,
  Button,
  useColorModeValue,
  useTheme
} from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";
import getData, { keys } from "../../data";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineArrowRight } from 'react-icons/ai'
const Profile = (props) => {
  let history = useHistory();
  const theme = useTheme()
  let data = getData(props.props.data.school, keys.PERSONS);

  return (
    <Box
      py="50px"
      position="relative"
      mt={["22em", "40px", "180px", "280px"]}  // template 3

      mx="0px"
      px="5px"
      overflowX="hidden"

    >
      <Text
        fontWeight="bolder"
        fontSize={["3xl", "4xl"]}
        textAlign="center"
      >
        An Introduction
      </Text>
      <Text
        fontSize={["1xl", "xl"]}
        textAlign="center"
        px={["10px", "10px", "50px", "250px"]}
      >
        {getData(props.props.data.school, keys.INTRO)}
      </Text>

      <Flex
        p={[5, 10]}
         alignItems="stretch"
        justify="center"
        flexWrap="wrap"
      >
        {data.map((person) =>
          person.name  && person.message ? (
            <Flex
              position="relative"
              border='1px solid'
              borderColor='gray.100'
              my="5px"
              mx={["3px", "20px"]}
              h={["25em", "30em"]}
              w={["full", "200px", "450px", "350px"]}
              bg={useColorModeValue("white", "gray.800")}
              shadow="md"
              borderRadius="0.8em"
              direction="column"
              justify="center"
              overflow="hidden"
              boxShadow="2xl"
              padding='13px 25px'
              // border={["5px solid white", "25px solid white"]}
              _hover={{
                boxShadow: "xl",
              }}
            >
              <Image
                mt={2}
                flex='1'
                h={["150px", "280px"]}
                objectFit="fill"
                src={person.image}
                fallbackSrc={`https://via.placeholder.com/500x700.png?text=${person.name}`}
              />

              <Box
            
                position="relative"
                zIndex="1"
                top={["-55px", "-30px", "-58px", "-100px"]}
                color="black"
                p={["1px", "5px"]}
                bg={transparentize("gray.100", 0.8)}
                w={["full", "66%"]}
              >
                <Text

                  fontSize={["20px", "sm", "md", "1xl"]}
                  fontWeight="bolder"
                  px={["2px", "5px"]}
                >
                  {person.name}
                </Text>
                <Text
                  px={["2px", "5px"]}

                  fontSize={["16px", "sm", "md", "md"]}
                >
                  {String(person.designation_name ? person.designation_name : person.designation).toUpperCase()}
                </Text>
              </Box>
              <Box
               flex='1'
                pt={['3px','15px']}
                textAlign="justify"
                mt={["-20px", "-50px"]}
                fontSize="sm"
                letterSpacing="0.28px"
                noOfLines={[5, 4, 4]}
                overflow="hidden"
                color="#252B33"
                mb={["20px","60px"]}
                color='gray.600'
                maxH='8em'
              >
                {person.message}
              </Box>


              {/* <Button
                as="a"
                href={`/desk/${person.designation}`}
                  my="1em"
                  h="2.5em"
                  px={["2px", "10px"]}
                  bg="#fe4980"
                  color="white"
                  textDecoration="none"
                  fontSize="xs"
                  textAlign="center"
                >
                  Read more
                </Button> */}
              <Box as={Link} to={`/desk/${person.designation}`} mt="-1em" color="white" position="absolute" bottom="0" pl='2em' pt='1em' pb='0.5em' pr='0.5em' borderTopLeftRadius="8em" right="0" _hover={{
                opacity: '0.8'
              }}
                bg={theme.primary}

                >
                <AiOutlineArrowRight fontSize="1.5em" pt="4px" pl="8px" />
              </Box>
            </Flex>
          ) : null
        )}
      </Flex>
    </Box>
  );
};

export default Profile;
