import React, { useRef } from 'react'
import OnlineAdmission from './OnlineAdmission'
import Footer from '../components/Footer'
import { SchoolName } from '../components/Header'
import MobileHeader from '../components/MobileHeader'
import {
    Box,
    Heading,
    Container,
    Text,
    Button,
    Stack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,

    useTheme,
} from '@chakra-ui/react'
const EXAM_DETAILS = {
    "AXw5ObED4E7lrNpy":
    {
        'name': "Saint John's Talent Search Exam",
        'desc': "(STSE 2022 PHASE II)",
        'registration_date': '9 May 2022 (Monday)',
        'exam_date': "22 May 2022 (Sunday)",
        'timing': "11:00 AM to 12:00 AM",
        'exam_mode': "Online",
        'for_classes': "6 to 11"
    },

    "jazXD7g1w3LmKr45":
    {
        'name': "Unique Scholarship And Admission Test",
        'desc': "(USAT-2022 PHASE II)",
        'registration_date': '8 May 2022 (Sunday)',
        'exam_date': "22 May 2022 (Sunday)",
        'timing': " 09:00 AM to 10:00 AM",
        'exam_mode': "Online",
        'for_classes': "6 to 12"
    },
}
const ScholarshipAdmission = props => {
    const theme = useTheme()
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    let examDetails = EXAM_DETAILS[props?.data?.school.id] ? EXAM_DETAILS[props.data.school.id] : EXAM_DETAILS['jazXD7g1w3LmKr45']

    return (
        <div>
            <Box
                position="sticky"
                className="browserHeader"
                top={0}
                right={0}
                zIndex="10"
                left={0}
            >
                <SchoolName {...props} />
                <hr />
            </Box>
            <MobileHeader {...props} />
            <ExamDetails executeScroll={executeScroll} examDetails={examDetails} />
            <Heading textAlign={'center'} ref={myRef}
                fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}

            >Student Registation Form</Heading>
            <OnlineAdmission {...props} heading={false} hidePrint={true} />
            <Footer {...props} hideContact={true} />
        </div>
    )
}

const ExamDetails = ({ executeScroll, examDetails }) => {
    const theme = useTheme()
    return (
        <Container maxW={'5xl'}>
            <Stack
                as={Box}
                textAlign={'center'}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 16, md: 24 }}>
                <Heading
                    fontWeight={600}
                    fontSize={{ base: '2xl', sm: '4xl', md: '5xl' }}
                    lineHeight={'110%'}>
                    {examDetails.name} <br />
                    <Text as={'span'} color={theme.primary}>
                        {examDetails.desc}
                    </Text>
                </Heading>



                {/* <DetailRow */}
                <Text color={'gray.500'}>
                    <TableContainer maxW='400px' margin={'auto'}>
                        <Table variant={'striped'} size='sm'>
                            <Tbody>
                                <DetailRow title={'Registration Date'} value={examDetails.registration_date} />
                                <DetailRow title={'Exam Date'} value={examDetails.exam_date} />
                                <DetailRow title={'Timing'} value={examDetails.timing} />
                                <DetailRow title={'For Classes'} value={examDetails.for_classes} />
                                <DetailRow title={'Mode'} value={examDetails.exam_mode} />

                            </Tbody>

                        </Table>

                    </TableContainer>
                </Text>
                <Stack
                    direction={'column'}
                    spacing={3}
                    align={'center'}
                    alignSelf={'center'}
                    position={'relative'}>
                    <Button
                        onClick={executeScroll}
                        colorScheme={'green'}
                        // bg={'green.400'}
                        rounded={'full'}
                        px={6}
                        size='lg'
                    >
                        Apply Now
                    </Button>
                    {/* <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
                        Learn more
                    </Button> */}
                    <Box>
                        {/* <Icon
                        as={Arrow}
                        color={useColorModeValue('gray.800', 'gray.300')}
                        w={71}
                        position={'absolute'}
                        right={-71}
                        top={'10px'}
                    /> */}
                        {/* <Text
                        fontSize={'lg'}
                        fontFamily={'Caveat'}
                        position={'absolute'}
                        right={'-125px'}
                        top={'-15px'}
                        transform={'rotate(10deg)'}>
                        Starting at $15/mo
                    </Text> */}
                    </Box>
                </Stack>
            </Stack>
        </Container>
    )

}

const DetailRow = ({ title, value }) => {
    return (
        <Tr>
            <Td fontSize={'0.90rem'} paddingY='10px'>{title}</Td>
            <Td fontSize={'0.90rem'} paddingY='10px' fontWeight={'bold'}>{value}</Td>
        </Tr>
    )

}

export default ScholarshipAdmission