import React from "react";
import { AspectRatio } from "@chakra-ui/layout";
const Map = ({ data }) => {
	let urlData = data.data.school.website.data?.map_embed_link
	const regex = /(https?:\/\/[^\s]+)/g;
	let url;
	url = urlData.match(regex)?.[0];
	return (
		<>
			{
				url ?
					<div>
						<AspectRatio maxH="500px" ratio={16 / 9}>
							<iframe src={url} >

							</iframe>
						</AspectRatio>


					</div>
					: null
			}
		</>

	);
};


export default Map;
