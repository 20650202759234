import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useTheme,
} from '@chakra-ui/react';
import React from 'react';
import { AiFillCheckCircle} from 'react-icons/ai';
import getData, { convertHTMLString, keys } from '../data';
import { NewLink } from '../data/Path';
import _ from 'lodash'
import { isPersonVisible } from '../data/Menu';
import { useHistory } from 'react-router-dom';

const NewsAndEvents = (props) => {
  let theme = useTheme();
  console.log("props",props);

  return (
    <Box
      px={{base:6, md:16}}
      py="10"
      bg="transparent linear-gradient(174deg, #F6F7F9 0%, #F6F7F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box"
    >
      <Text color={theme.secondary} fontSize={{base:"14px", md:'18px'}}>WORLD-CLASS EDUCATORS</Text>
      <Flex flexWrap="wrap">
        <Box w={{ base: '100%', sm: '100%', lg: '25%' }} pr={8}>
          <Box mb={8}>
            <Heading color="#22355D" fontSize={{base:"24px", md:'32px'}}>
              Latest News & Events
            </Heading>
            <Flex
              alignItems="stretch"
              flexDirection="column"
              borderRadius="10px"
              boxShadow="0px 0px 3px #28282838"
              bg="#F6F7F9"
              height="450px"
              p="5"
              mt="5"
            >
              <Box color="#22355D" fontWeight="bold">
                {' '}
                NEWS & ANOUNCEMENTS
              </Box>
              <marquee direction="up" id="marquee1" height={450}>
                {getData(props?.data?.school, keys.ANNOUNCEMENTS).map((d, i) => 
                  <Flex mb="4" key={i} color="#252B33" fontSize="14px">
                    <Box pr={4}>
                      <AiFillCheckCircle color="#85C1E9" fontSize="18px" />
                    </Box>
                    <Box dangerouslySetInnerHTML={convertHTMLString(d.text)}/>
                  </Flex>
                )}
              </marquee>
            </Flex>
          </Box>
        </Box>
        <Flex flexWrap="wrap" w={{ base: '100%', sm: '100%', lg: '75%' }}>
          {getData(props?.data?.school, keys.PERSONS).map((val, i) =>{
            const features = getData(props?.data?.school, keys.FEATURES);
            if (_.findIndex(features, f => isPersonVisible(val, f)) == -1) return null;
            return (
              <PersonCard key={i} data={val}/>
            )
          })}
        </Flex>
      </Flex>
    </Box>
  );
};

const PersonCard = ({data}) => {
  const theme = useTheme()
  let history = useHistory()

  return(
      <Box
        mr={4}
        mb={8}
        w={{ base: '100%', sm: '100%', lg: '31%' }}
        border="1px solid #2828281C"
        position="relative"
        borderRadius="6px"
        bg="white"
      >
        <Box p={5}>
          <Flex alignItems="flex-end" w='100%'>
            <Box
              bg="#FDFDFE"
              mr={3}
              overflow="hidden"
              borderRadius="40px 0px 0px 0px"
              w="162px"
              h="162px"
              border="1px solid #2828281C"
              p={2}
            >
              <Image
                flexShrink={0}
                w="142px"
                h="142px"
                borderRadius='40px 0px 0px 0px'
                border='1px solid grey'
                src={data.image || '/images/img_avatar2.png'}
              />
            </Box>
            <Box textAlign="bottom">
              <Text color={theme.primary} fontSize="md" fontWeight="bold">
                {data.name}
              </Text>
              <Text color={theme.secondary}>{_.capitalize(data.designation_name ? data.designation_name : data.designation)}</Text>
            </Box>
          </Flex>
          <br />
          <Box
            fontSize="sm"
            letterSpacing="0.28px"
            className="text-ellipsis"
            color="#252B33"
          >
            {data.message}
          </Box>
        </Box>
        <br />
        <br />
        <Button
          onClick={() => NewLink(history, `/desk/${data.designation}`)}
          color="white"
          size="sm"
          position="absolute"
          bottom={0}
          right={0}
          borderRadius="10px 0 0 0"
          bg={theme.primary}
        >
          Read more here
        </Button>
      </Box>
  )
}

export default NewsAndEvents;