import { Box, Flex, Image, Text, useTheme } from '@chakra-ui/react';
import React, { useEffect } from 'react';

const MainContainer = ({ children, heading }) => {
  let theme = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box p={heading != undefined ? {base:5, md:14} : {base:2, md:6}} position="relative">
      {heading ?<><Flex justifyContent="center">
        <Box textAlign='center'>
          <Text fontSize={{base:'3xl', md:'4xl'}} textAlign="center" color={theme.primary}>
            {heading}
          </Text>
          {heading != undefined ? <Image marginLeft='auto' width={{base:40, sm:50, md:60}} marginRight='auto' display='block' src="/images/Shape9.svg" /> : null}
        </Box>
      </Flex> 
      <br />
      <br /></>: undefined}
      <Box fontSize="18px" minHeight='400px' color="#34495E">
        {children}
      </Box>
    </Box>
  );
};

export default MainContainer;
