import { Box, Container, Flex, Text, useTheme, Image } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import MainContainer from '../components/MainContainer';
import getData, { convertHTMLString, keys } from '../data';
import {getBirthdays} from "../redux/actions/index"
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';


const Birthdays = props => {
  const theme = useTheme();
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBirthdays(props.data.school.id));
  }, []);
  return (
    <Box background="url(/images/birthday-background.jpeg)" p={{base:2, md:''}}>
      <br />
      <Flex justifyContent="center">
        <Box>
          <Image
            src="/images/happy-birthday-heading.png"
            h={{base:'40%', md:'100%'}}
          />
        </Box>
      </Flex>
      <br />
      <Flex flexWrap="wrap" p={10}>
        {_.orderBy(props.data.birthdays, student =>
          moment(student.dob.date).format('MM-DD')
        ).map(student => (
          <Box
            key={student.id}
            w={{ base: '100%', sm: '100%', lg: '25%' }}
            px={4}
          >
            <Container className="birthday-card" background="white" my={8}>
              <img
                src={student.avatar.image || '/images/img_avatar2.png'}
                style={{ height: '220px', width: '100%' }}
              />
              <Box className="birthday-card-container" textAlign="center" p={4}>
                <Text fontWeight="bold" color={theme.secondary} fontSize="2xl">
                  {student.name}
                </Text>
                <Text>{`${student.standard_name}-${_.capitalize(
                  student.section_name
                )}`}</Text>
                <Text color="#566573">
                  <b>({moment(student.dob.date).format('DD-MM-YYYY')})</b>
                </Text>
              </Box>
            </Container>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default Birthdays;
